import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import ModalBox from '../ModalBox/ModalBox';
import { useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from './styles';
import client from '../../GraphQL/client';
import editActualWeightParcel from '../../GraphQL/mutations/editActualWeightParcel';
// @ts-ignore
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface FormActualWeightProps {
  isOpenEditActualWeight: boolean;
  setOpenEditActualWeight: React.Dispatch<React.SetStateAction<boolean>>;
  parcelId: string;
}

export default function FormActualWeight({
  isOpenEditActualWeight,
  setOpenEditActualWeight,
  parcelId,
}: FormActualWeightProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [actualWeightValue, setActualWeightValue] = useState<string | null>(
    null,
  );
  const [actualWeightValueError, setActualWeightValueError] = useState<
    string | null
  >(null);

  const { t } = useTranslation();

  const ActualWeightSchema = Yup.object().shape({
    actualWeight: Yup.number().test({
      test: () => {
        return true;
      },
    }),
  });

  const handlerEdit = () => {
    setLoadingAction(true);
    client
      .mutate({
        mutation: editActualWeightParcel,
        variables: {
          id: parcelId,
          actualWeight: actualWeightValue,
        },
      })
      .then((response) => {
        enqueueSnackbar('Вес успешно изменен.', { variant: 'success' });
        setLoadingAction(false);

        setOpenEditActualWeight(false);
        setActualWeightValue(null);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        setLoadingAction(false);
      });
  };

  const onClose = (isClose: boolean) => {
    setOpenEditActualWeight(isClose);
    setActualWeightValue(null);
  };

  return (
    <ModalBox isOpen={isOpenEditActualWeight} setOpen={onClose}>
      <InputLabel shrink={false} htmlFor={'input-actual-weight'}>
        {t('app.уntertheactualweightoftheshipment')}:
      </InputLabel>
      <Input
        disableUnderline
        id={'input-actual-weight'}
        value={actualWeightValue}
        inputProps={{
          type: 'number',
          min: 0,
          step: 0.01,
        }}
        startAdornment={<InputAdornment position='end'>кг</InputAdornment>}
        onChange={(e) => {
          if (e.target.value.length) {
            ActualWeightSchema.validate({ actualWeight: e.target.value })
              .then((valid: { actualWeight: string }) => {
                setActualWeightValueError(null);
                setActualWeightValue(valid.actualWeight);
              })
              .catch(() =>
                setActualWeightValueError(
                  t('app.mustbeAnumberfromperiod', { from: 0, to: 70 }),
                ),
              );
          } else {
            setActualWeightValue(e.target.value);
            setActualWeightValueError(t('app.requiredField'));
          }
        }}
      />
      {actualWeightValueError && (
        <FormHelperText className={classes.errorMsg}>
          {actualWeightValueError}
        </FormHelperText>
      )}

      <div className={classes.boxModalButtons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            setOpenEditActualWeight(false);
          }}
        >
          {t('app.close')}
        </Button>
        <Button
          variant='contained'
          onClick={handlerEdit}
          disabled={
            isLoadingAction || !!actualWeightValueError || !actualWeightValue
          }
        >
          {isLoadingAction ? <CircularProgress size={22} /> : <SaveIcon />}
          {t('app.save')}
        </Button>
      </div>
    </ModalBox>
  );
}
