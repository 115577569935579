import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Else, If, Then } from 'react-if';
import { useMutation } from '@apollo/client';
import useStyles from './styles';
import UserRowItem from '../../components/UserRowItem';
import { LIMIT_ITEM_PAGE } from '../../utils/helpers';
import { MUTATION_USER_DISCOUNT_MANAGMENT } from '../../GraphQL/mutations/setUserDiscount';
import { ShowLoadingText } from '../../utils/helperComponents';
import {
  useVerifyPassportDataMutation,
  useVerifyLegalDataMutation,
  useUpdateCreditLimitForUserMutation,
  useGetCurrentUserQuery,
  User,
  useUsersLazyQuery,
  UserOrder,
} from '../../generated/graphql';
import { ModalDiscount, useDiscount } from './discount';
import { useCredit, ModalCredit } from './creditLimit';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import PaginationComponent from '../../components/PaginationComponent/PaginationComponent';
import { IFilters } from '../../utils/types';
import FilterSelectSortingUsers from '../../components/FilterComponents/FilterSelectUsers/FilterSelectUsers';
import FilterInputSearch from '../../components/FilterComponents/FilterInputSerch/FilterInputSerch';
import FilterSelectUsersBySortType from '../../components/FilterComponents/FilterSelectUsers/FilterSelectUsersBySortType';
import FilterSelectLimitItems from '../../components/FilterComponents/FilterSelectLimitItems/FilterSelectLimitItems';

type HandleSetCreditLimitArg = {
  userId: number;
  value: number;
};

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

export default function Users() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [users, setUsers] = useState<User[] | []>([]);
  const [filter, setFilter] = useState<IFilters>({
    search: '',
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const [usersFilter, setUsersFilter] = useState<UserOrder>({});

  const {
    isDiscountModalOpen,
    setIsDiscountModalOpen,
    discountUserId,
    setDiscountUserId,
    discountValue,
    setDiscountValue,
    discountValueError,
    setDiscountValueError,
    isDiscount,
    setIsDiscount,
  } = useDiscount();

  const {
    isCreditModalOpen,
    setIsCreditModalOpen,
    setUserId,
    userId,
    creditValue,
    setCreditValue,
  } = useCredit();

  const { data: currentUserData } = useGetCurrentUserQuery();

  const [getUsersLazyQuery, { data, loading }] = useUsersLazyQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [setUserDiscountMutation] = useMutation(
    MUTATION_USER_DISCOUNT_MANAGMENT,
  );

  const [verifyPassportDataMutation] = useVerifyPassportDataMutation();

  const [verifyLegalDataMutation] = useVerifyLegalDataMutation();

  useEffect(() => {
    if (data?.users.rows) {
      setUsers(data.users.rows as User[]);
    }
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [filter, usersFilter]);

  const refetch = () => {
    getUsersLazyQuery({
      variables: {
        offset: (filter.page - 1) * filter.itemsLimit!,
        limit: filter.itemsLimit,
        search: filter.search,
        order: usersFilter,
      },
    });
  };

  const [updateCreditLimitForUserMutation] =
    useUpdateCreditLimitForUserMutation();

  const handleSetCreditLimit = ({ userId, value }: HandleSetCreditLimitArg) => {
    updateCreditLimitForUserMutation({
      variables: {
        userId: userId,
        value: value,
      },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.successInfo'), { variant: 'success' });
          refetch();
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });
  };

  return (
    <>
      <Typography variant='h2' align='center'>
        {t('app.customers')}
      </Typography>
      <div className={classes.filters}>
        <FilterSelectSortingUsers
          value={usersFilter.rule}
          setFilter={setUsersFilter}
        />
        {usersFilter.rule ? (
          <FilterSelectUsersBySortType
            value={usersFilter.way}
            setFilter={setUsersFilter}
          />
        ) : null}
        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={LOCAL_STORAGE_KEYS.ITEMS_LIMIT}
        />
        <FilterInputSearch setFilter={setFilter} />
      </div>
      <If condition={loading}>
        <Then>
          <div>
            <ShowLoadingText />
          </div>
        </Then>
        <Else>
          <TableContainer component={Paper}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>№</TableCell>
                  <TableCell align='center'>{t('app.date')}</TableCell>
                  <TableCell align='center'>{t('app.fullName')}</TableCell>
                  <TableCell align='center'>{t('app.balance')}</TableCell>
                  <TableCell align='center'>{t('app.company')}</TableCell>
                  <TableCell align='center'>{t('app.address')}</TableCell>
                  <TableCell align='center'>{t('app.phone')}</TableCell>
                  <TableCell align='center'>Email</TableCell>
                  <TableCell align='center'>{t('app.verification')}</TableCell>
                  <TableCell align='center'>{t('app.creditLimit')}</TableCell>
                  <TableCell align='center'>{t('app.discount')}</TableCell>
                  <TableCell align='center'>
                    {t('app.storageDiscount')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(users as User[]).map((user) => {
                  return (
                    <UserRowItem
                      key={user.id}
                      user={user}
                      setDiscountUserId={setDiscountUserId}
                      setIsDiscountModalOpen={setIsDiscountModalOpen}
                      setDiscountValue={setDiscountValue}
                      verifyPassportDataMutation={verifyPassportDataMutation}
                      verifyLegalDataMutation={verifyLegalDataMutation}
                      handleSetCreditLimit={handleSetCreditLimit}
                      isCreditModalOpen={isCreditModalOpen}
                      setIsCreditModalOpen={setIsCreditModalOpen}
                      setUserId={setUserId}
                      role={currentUserData?.currentUser?.role}
                      setIsDiscount={setIsDiscount}
                      setCreditValue={setCreditValue}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Else>
      </If>
      <PaginationComponent
        count={data?.users?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />
      <ModalDiscount
        isDiscountModalOpen={isDiscountModalOpen}
        setIsDiscountModalOpen={setIsDiscountModalOpen}
        discountUserId={discountUserId}
        users={users}
        discountValue={discountValue}
        setDiscountValueError={setDiscountValueError}
        setDiscountValue={setDiscountValue}
        discountValueError={discountValueError}
        classes={classes}
        setDiscountUserId={setDiscountUserId}
        setUserDiscountMutation={setUserDiscountMutation}
        enqueueSnackbar={enqueueSnackbar}
        setUsers={setUsers}
        isDiscount={isDiscount}
      />
      <ModalCredit
        isCreditModalOpen={isCreditModalOpen}
        setIsCreditModalOpen={setIsCreditModalOpen}
        users={users}
        classes={classes}
        userId={userId}
        handleSetCreditLimit={handleSetCreditLimit}
        userCreditLimit={creditValue}
      />
    </>
  );
}
