import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { BoxCentered } from '../../BoxCentered/BoxCentered';
import useStyles from '../styles';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../../utils/types';
import { itemsOptions } from './options';

const FilterSelectLimitItems: React.FC<{
  value?: number;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
  localStorageItemsLimitKey: string;
}> = ({ value, setFilter, localStorageItemsLimitKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onChangeHandler = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    localStorage.setItem(localStorageItemsLimitKey, e.target.value as string);
    setFilter((state) => ({
      ...state,
      itemsLimit: e.target.value as number,
      page: 1,
    }));
  };

  return (
    <FormControl className={classes.filtersItemSelect}>
      <BoxCentered pl={2}>
        <span>{t('app.show')}</span>
        <Select
          displayEmpty
          disableUnderline
          value={value}
          onChange={onChangeHandler}
        >
          {itemsOptions.map((value) => (
            <MenuItem key={value} value={value}>
              {value.toString()}
            </MenuItem>
          ))}
        </Select>
      </BoxCentered>
    </FormControl>
  );
};

export default FilterSelectLimitItems;
