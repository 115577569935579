import { TRoutes } from '../utils/helpers';
import Users from '../viewes/Users/Users';
import ParcelsAllHistory from '../viewes/ParcelsAllHistory/ParcelsAllHistory';
import ParcelDetail from '../viewes/ParcelDetail/ParcelDetail';
import ParcelTrack from '../viewes/ParcelTrack/ParcelTrack';
import PaymentStatistics from '../viewes/PaymentStatistics/PaymentStatistics';
import PaymentsHistory from '../viewes/PaymentsHistory/PaymentsHistory';
import SettingsCalculator from '../viewes/Settings/SettingsCalculator/SettingsCalculator';
import Reports from '../viewes/Reports/Reports';
import ShipmentsTabs from '../viewes/ShipmentsTabs/ShipmentsTabs';
import { UserRoles } from '../generated/graphql';
import { SenderAccountsList } from '../viewes/Settings/SenderAccountsList/SenderAccountsList';
import SenderAccountsForm from '../viewes/Settings/SenderAccountsForm/SenderAccountsForm';

export const routes = [
  {
    path: TRoutes.MAIN,
    component: ShipmentsTabs,
    roles: ['SUPER_ADMIN', 'ADMIN'],
  },
  { path: TRoutes.CLIENTS, component: Users, roles: ['SUPER_ADMIN', 'ADMIN'] },
  {
    path: TRoutes.PARCELS,
    component: ShipmentsTabs,
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  {
    path: TRoutes.PARCELS_USER,
    component: ParcelsAllHistory,
    roles: ['SUPER_ADMIN'],
  },
  {
    path: TRoutes.PARCEL_DETAIL,
    component: ParcelDetail,
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  {
    path: TRoutes.TRACK,
    component: ParcelTrack,
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  {
    path: TRoutes.REPORTS,
    component: Reports,
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  {
    path: TRoutes.PARCEL_TRACK_WITH_CARRIER,
    component: ParcelTrack,
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  {
    path: TRoutes.PAYMENT_STATISTICS,
    component: PaymentStatistics,
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  {
    path: TRoutes.PAYMENT_STATISTICS_PER_USER,
    component: PaymentsHistory,
    roles: ['ADMIN', 'SUPER_ADMIN'],
  },
  {
    path: TRoutes.SETTINGS_CALCULATOR,
    component: SettingsCalculator,
    roles: ['SUPER_ADMIN'],
  },
  {
    path: TRoutes.SETTINGS_SENDER_ACCOUNTS,
    component: SenderAccountsList,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.SETTINGS_SENDER_ACCOUNTS_CREATE,
    component: SenderAccountsForm,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.SETTINGS_SENDER_ACCOUNTS_UPDATE,
    component: SenderAccountsForm,
    roles: [UserRoles.SuperAdmin],
  },
];
