import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    '& .MuiBox-root': {
      padding: '0px',
    },
  },
  tabs: {
    display: 'contents',
    width: '480px',
  },
}));

export default styles;
