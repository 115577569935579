import { SortType, UserOrderRules } from '../../../generated/graphql';

export const filterSelectSortingOptions = [
  { title: 'app.sortingFilter', value: '' },
  { title: 'app.balanceFilter', value: UserOrderRules.Balance },
  {
    title: 'app.lastTransactionFilter',
    value: UserOrderRules.LastTransaction,
  },
  {
    title: 'app.verificationFilter',
    value: UserOrderRules.Verification,
  },
];
export const filterSelectBySortOptions = [
  { title: 'app.asc', value: SortType.Asc },
  { title: 'app.desc', value: SortType.Desc },
];
