import * as React from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import {
  FastField,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
} from 'formik';
import styles from './styles';
import SaveIcon from '@material-ui/icons/Save';
import {
  useGetConfigurationListLazyQuery,
  useUpdateConfigurationMutation,
} from '../../../generated/graphql';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SettingsCalculator: React.FC = () => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [
    queryLazyGetConfigurationList,
    {
      data: dataQueryLazyGetConfigurationList,
      loading: isLoadingQueryLazyGetConfigurationList,
      error: errorQueryLazyGetConfigurationList,
    },
  ] = useGetConfigurationListLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [mutationUpdateConfiguration, { loading: isLoadingUpdate }] =
    useUpdateConfigurationMutation();

  useEffect(() => {
    queryLazyGetConfigurationList();
    // eslint-disable-next-line
  }, []);

  errorQueryLazyGetConfigurationList?.message &&
    enqueueSnackbar(errorQueryLazyGetConfigurationList?.message, {
      variant: 'error',
    });

  if (isLoadingQueryLazyGetConfigurationList)
    return <ShowLoadingText name={t('app.previousSettings')} />;
  if (
    !dataQueryLazyGetConfigurationList ||
    !dataQueryLazyGetConfigurationList?.getConfigurationList
  ) {
    return <BoxCentered>Нет данных</BoxCentered>;
  }

  const initialValues = {
    rateUSD: dataQueryLazyGetConfigurationList?.getConfigurationList?.rateUSD,
    extraCharge:
      dataQueryLazyGetConfigurationList?.getConfigurationList?.extraCharge,
  };

  const handleSubmit = (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    mutationUpdateConfiguration({
      variables: {
        input: [
          { key: 'rateUsd', value: JSON.stringify(values.rateUSD) },
          { key: 'extraCharge', value: JSON.stringify(values.extraCharge) },
        ],
      },
    })
      .then((r) => {
        if (r?.data?.updateConfiguration) {
          enqueueSnackbar(t('app.settingsSaved'), { variant: 'success' });
          queryLazyGetConfigurationList();
        }
      })
      .catch((reason) => {
        reason?.message &&
          enqueueSnackbar(reason?.message, { variant: 'error' });
        console.error(reason);
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {t('app.calculatorSettings')}
      </Typography>

      <Divider className={classes.titleDivider} />
      <br />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // @ts-ignore
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => {
          return (
            <Form noValidate autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.dollarExchangeRate')}
                    name='rateUSD'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>RUB</InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.extraCharge')}
                    name='extraCharge'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    startIcon={
                      isLoadingUpdate ? (
                        <CircularProgress size={22} />
                      ) : (
                        <SaveIcon />
                      )
                    }
                  >
                    {t('app.save')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default SettingsCalculator;

type Props = {
  classes?: any;
  setFieldValue: (name: string, value: string | number | null) => void;
  label: string;
  name: string;
  inputProps?: any;
  startAdornment?: React.ReactNode;
  fullWidth?: boolean;
};

const InputCalculator = ({
  classes,
  setFieldValue,
  label,
  name,
  inputProps,
  startAdornment,
  fullWidth = false,
}: Props) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {label}
      </Grid>
      <Grid item xs={12} sm={4}>
        <FastField name={name}>
          {({ field: { value, ...field }, meta }: FieldProps) => (
            <FormControl error={meta.touched && !!meta.error}>
              <Input
                disableUnderline
                id={name}
                defaultValue={value}
                className={fullWidth ? '' : classes.inputNumber}
                {...field}
                startAdornment={startAdornment}
                inputProps={inputProps}
                onChange={(event) => setFieldValue(name, +event.target.value)}
              />
              {meta.touched && !!meta.error && (
                <FormHelperText>{meta.error}</FormHelperText>
              )}
            </FormControl>
          )}
        </FastField>
      </Grid>
    </Grid>
  );
};
