import React, { useState } from 'react';
import useStyles from './styles';
import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';

import ParcelsAllHistory from '../ParcelsAllHistory/ParcelsAllHistory';
import FilterInputSearch from '../../components/FilterComponents/FilterInputSerch/FilterInputSerch';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { useGetCurrentUserQuery } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface IFilterTabs {
  search: string;
  page: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`shipments-tabpanel-${index}`}
      aria-labelledby={`shipments-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `shipments-tab-${index}`,
    'aria-controls': `shipments-tabpanel-${index}`,
  };
}

export default function ShipmentsTabs() {
  const classes = useStyles();
  const [value, setValue] = useState(
    Number(localStorage.getItem(LOCAL_STORAGE_KEYS.TAB_VALUE)) || 0,
  );

  const { t } = useTranslation();

  const [filterTabs, setFilterTabs] = useState<IFilterTabs>({
    search: '',
    page: 1,
  });

  const { data: currentUserData } = useGetCurrentUserQuery();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TAB_VALUE, newValue.toString());
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography variant='h2' align='center' style={{ marginBottom: '24px' }}>
        {t('app.allShipments')}
      </Typography>
      <AppBar className={classes.tabs} color='inherit' position='static'>
        {currentUserData?.currentUser?.role === 'SUPER_ADMIN' ? (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='shipments tabs'
          >
            <Tab label={t('app.shipments')} {...a11yProps(0)} />
            <Tab label={t('app.custom')} {...a11yProps(1)} />
            <FilterInputSearch setFilter={setFilterTabs} />
          </Tabs>
        ) : null}
      </AppBar>
      {currentUserData?.currentUser?.role === 'SUPER_ADMIN' ? (
        <>
          <TabPanel value={value} index={0}>
            <ParcelsAllHistory
              userAccount={false}
              filterTabs={filterTabs}
              type='SHIPMENT'
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ParcelsAllHistory
              userAccount={true}
              filterTabs={filterTabs}
              type='CUSTOM'
            />
          </TabPanel>
        </>
      ) : null}
    </div>
  );
}
