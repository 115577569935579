import gql from 'graphql-tag';

export const QUERY_VALIDATE_ADDRESS = gql`
  query validationAddress($input: ValidationAddress) {
    validationAddress(input: $input) {
      address {
        inputValue
        returnValue
      }
      city {
        inputValue
        returnValue
      }
      state {
        inputValue
        returnValue
      }
      postalCode {
        inputValue
        returnValue
      }
    }
  }
`;
