import gql from 'graphql-tag';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';

export const QUERY_PARCEL = gql`
  ${DELIVERY_COUNTRY}
  query parcel($id: ID!) {
    parcel(id: $id) {
      id
      user {
        id
        name
      }
      sender {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
      }
      receiver {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      packages {
        id
        weightKg
        lengthCm
        widthCm
        heightCm
        units {
          id
          nameRU
          nameEN
          tradeMark
          code
          quantity
          price
          country {
            ...FragmentDeliveryCountry
          }
          netWeight
          state
        }
      }
      typeDelivery {
        id
        name
        serviceName
      }
      fileTokenInvoiceXlsx
      senderNotes
      senderMark
      additionalInsurance
      declaredAmount
      declaredWeight
      actualAmount
      actualWeight
      trackNumber
      carrierCode
      deliveryStatusId
      paymentStatusId
      currency
      sentToWarehouseId
      exportReason
      paymentOfTaxes
      vatKey
      vatID
      isConsolidationPiece
      consolidationTrackNumber {
        service
        consolidationTrackNumber
      }
      barcode
      pathNameFile
      orderCostCurrency
      createdAt
      warehouseId
      warehouse {
        id
        code
        address
        address2
        company
        phone
        country {
          ...FragmentDeliveryCountry
        }
        managerName
        city
        zipCode
        state
        email
      }
    }
  }
`;
