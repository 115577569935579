import React from 'react';
import moment from 'moment';
import { Link, NavLink } from 'react-router-dom';
import {
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import iconBarcode from '../../assets/img/icon_barcode.svg';

import { Edit as EditIcon } from '@material-ui/icons';
import { getStatusIdByName, TRoutes } from '../../utils/helpers';
import useStyles from './styles';
import {
  ConvertStatusIdToText,
  GetCarrierCodeImage,
} from '../../utils/helperComponents';
import { StoreContext } from '../../utils/store';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {
  COLORS,
  DATE_FORMAT,
  DELIVERY_STATUS_BY_ID,
  PAYMENT_ORDER_STATUS_BY_ID,
} from '../../utils/constants';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { Parcel } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

interface ParcelTableRowItemProps {
  parcel: Parcel;
  setIsOpenEditTrackNumber: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  setIsOpenRemoveTrackNumber: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  setTrackNumberToRemove: (value: unknown) => void;
  setCurrentChoiceParcel: (value: unknown) => void;
  setIsOpenConfirmCancel: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  setParcelToCancel: (value: unknown) => void;
  setActualWeightToRemove: (value: unknown) => void;
  setIsOpenRemoveActualWeight: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  userAccount: boolean;
  handleSetConsolidation: (value: string) => void;
  consolidation: Record<string, string>;
  type: string;
}

export default function ParcelTableRowItem(props: ParcelTableRowItemProps) {
  const classes = useStyles();
  const { statuses } = React.useContext(StoreContext);
  const { t } = useTranslation();

  const statusIdNew = getStatusIdByName(statuses?.delivery, 'new');
  const statusIdPending = getStatusIdByName(statuses?.delivery, 'pending');
  const statusIdCancel = getStatusIdByName(statuses?.delivery, 'cancel');

  return (
    <TableRow>
      {/* № консолидацыя  */}
      <TableCell align='center'>
        <Link
          to={TRoutes.PARCEL_DETAIL.replace(':id', props.parcel.id.toString())}
        >
          {props?.parcel?.barcode?.substring(
            0,
            props?.parcel?.barcode.length - 4,
          )}
        </Link>
        <br />
        {moment(+(props.parcel.createdAt as string)).format(DATE_FORMAT)}
      </TableCell>
      <TableCell align='center'>
        <Link
          to={TRoutes.PAYMENT_STATISTICS_PER_USER.replace(
            ':userId',
            String(props?.parcel?.user?.id as number | string),
          )}
        >
          {props.parcel.user?.name}
        </Link>
      </TableCell>

      <TableCell align='center'>
        {props?.parcel?.actualAmount
          ? props?.parcel?.actualAmount.toFixed(2)
          : (props?.parcel?.declaredAmount as number).toFixed(2)}{' '}
        {props.parcel.orderCostCurrency}
      </TableCell>
      <TableCell align='center'>
        <BoxCentered>
          {props.parcel.trackNumber && props.parcel.carrierCode && (
            <>
              <GetCarrierCodeImage carrierCode={props.parcel.carrierCode} />
              <Tooltip
                title={`${t(
                  'app.trackShipment',
                )} ${props.parcel.carrierCode.toUpperCase()} ${
                  props.parcel.trackNumber
                }`}
              >
                <NavLink
                  className={classes.btnIcon}
                  to={TRoutes.PARCEL_TRACK_WITH_CARRIER.replace(
                    ':carrierCodeParam',
                    props.parcel.carrierCode,
                  ).replace(':trackNumberParam', props.parcel.trackNumber)}
                >
                  <span>{props.parcel.trackNumber}</span>
                </NavLink>
              </Tooltip>
            </>
          )}
          {props.parcel.deliveryStatusId !== statusIdCancel && (
            <Tooltip
              title={`${t('app.changeOrderTrackingNumber')} №${
                props.parcel.id
              }`}
            >
              <IconButton
                size='small'
                className={classes.btnIcon}
                onClick={() => {
                  props.setCurrentChoiceParcel(props.parcel);
                  props.setIsOpenEditTrackNumber(true);
                }}
              >
                <EditIcon className={classes.linkIcon} />
              </IconButton>
            </Tooltip>
          )}
          {props.parcel.trackNumber && props.parcel.carrierCode && (
            <Tooltip
              title={`${t('app.deleteOrderTrackingNumber')} №${
                props.parcel.id
              }`}
            >
              <IconButton
                size='small'
                onClick={() => {
                  props.setTrackNumberToRemove({
                    parcelId: props.parcel.id,
                    trackNumber: props.parcel.trackNumber,
                    carrierCode: props.parcel.carrierCode,
                  });
                  props.setIsOpenRemoveTrackNumber(true);
                }}
              >
                <DeleteForeverIcon style={{ color: COLORS.RED }} />
              </IconButton>
            </Tooltip>
          )}
        </BoxCentered>
      </TableCell>
      <TableCell align='center'>
        <ConvertStatusIdToText
          type='delivery'
          statusId={props.parcel.deliveryStatusId as number}
          defaultText={t(
            DELIVERY_STATUS_BY_ID[Number(props.parcel.deliveryStatusId)],
          )}
        />
      </TableCell>
      <TableCell align='center'>
        <ConvertStatusIdToText
          type='payment'
          statusId={props.parcel.paymentStatusId as number}
          defaultText={t(
            PAYMENT_ORDER_STATUS_BY_ID[Number(props.parcel.paymentStatusId)],
          )}
        />
      </TableCell>
      <TableCell align='center'>
        <Select value={t('app.action')}>
          <MenuItem
            value={t('app.action')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.action')}
          </MenuItem>

          <MenuItem disableGutters divider className={classes.actionMenuItem}>
            <Link
              className={classes.actionLink}
              to={TRoutes.PARCEL_DETAIL.replace(':id', props.parcel.id)}
            >
              <SearchIcon
                className={`${classes.actionIcon} ${classes.actionIconWatch}`}
              />
              {t('app.view')}
            </Link>
          </MenuItem>
          <MenuItem disableGutters divider className={classes.actionMenuItem}>
            <a
              href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${props?.parcel?.barcode}`}
              target='_blank'
              rel='noopener noreferrer'
              className={classes.actionLink}
            >
              <img
                src={iconBarcode}
                alt='barcode'
                className={`${classes.actionIcon}`}
                style={{ width: 20, height: 20 }}
              />
              {t('app.barcode')}
            </a>
          </MenuItem>
          <MenuItem disableGutters divider className={classes.actionMenuItem}>
            <a
              href={`${process.env.REACT_APP_API_DOMAIN}/api/getReceipt/${props?.parcel?.pathNameFile}`}
              target='_blank'
              rel='noopener noreferrer'
              className={classes.actionLink}
            >
              <SaveIcon className={`${classes.actionIcon}`} />
              {t('app.waybill')}
            </a>
          </MenuItem>
          <MenuItem disableGutters divider className={classes.actionMenuItem}>
            <Tooltip title={t('app.clickToOpenBarcode')}>
              <a
                href={`${process.env.REACT_APP_API_DOMAIN}/api/getInvoice/${props?.parcel?.fileTokenInvoiceXlsx}.xlsx`}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.actionLink}
              >
                <SaveIcon className={`${classes.actionIcon}`} />
                {t('app.invoiceXlsx')}
              </a>
            </Tooltip>
          </MenuItem>
          <MenuItem disableGutters divider className={classes.actionMenuItem}>
            <a
              href={`${process.env.REACT_APP_API_DOMAIN}/api/getReceipt/invoice_${props?.parcel?.pathNameFile}`}
              target='_blank'
              rel='noopener noreferrer'
              className={classes.actionLink}
            >
              <SaveIcon className={`${classes.actionIcon}`} />
              {t('app.invoice')}
            </a>
          </MenuItem>

          {props.type !== 'CUSTOM' &&
            props.parcel.id &&
            (props.parcel.deliveryStatusId === statusIdNew ||
              props.parcel.deliveryStatusId === statusIdPending) && (
              <MenuItem
                disableGutters
                divider
                className={classes.actionMenuItem}
              >
                <Link
                  className={classes.actionLink}
                  to={TRoutes.PARCEL_SEND.replace(
                    ':id',
                    props.parcel.id.toString(),
                  )}
                >
                  <LocalShippingIcon
                    className={`${classes.actionIcon} ${classes.actionIconSend}`}
                  />
                  {t('app.send')}
                </Link>
              </MenuItem>
            )}

          {props.type !== 'CUSTOM' &&
            props.parcel.deliveryStatusId !== statusIdCancel && (
              <MenuItem
                disableGutters
                divider
                className={classes.actionMenuItem}
              >
                <Link
                  to='#'
                  className={classes.actionLink}
                  onClick={() => {
                    props.setIsOpenConfirmCancel(true);
                    props.setParcelToCancel(props.parcel);
                  }}
                >
                  <CancelIcon
                    className={`${classes.actionIcon} ${classes.actionIconCancel}`}
                  />
                  {t('app.cancel')}
                </Link>
              </MenuItem>
            )}
        </Select>
      </TableCell>
    </TableRow>
  );
}
