import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  inputNumber: {
    width: 160,
  },
  titleDivider: {
    marginTop: 20,
  },
}));
