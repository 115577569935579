import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

const apolloCache = new InMemoryCache();

const link = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default new ApolloClient<NormalizedCacheObject>({
  link: authLink.concat(link),
  cache: apolloCache,
  typeDefs: '',
  resolvers: {},
});
