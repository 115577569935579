import gql from 'graphql-tag';

export default gql`
  mutation signInAdminMutation(
    $email: String!
    $password: String!
    $rememberMe: Boolean
  ) {
    signInAdmin(email: $email, password: $password, rememberMe: $rememberMe) {
      token
      message
    }
  }
`;
