import gql from 'graphql-tag';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';

export default gql`
  ${DELIVERY_COUNTRY}
  mutation editTrackNumberParcelMutation(
    $id: ID!
    $trackNumber: String!
    $carrierCode: String!
  ) {
    editTrackNumberParcel(
      id: $id
      trackNumber: $trackNumber
      carrierCode: $carrierCode
    ) {
      id
      sender {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      receiver {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      packages {
        id
        weightKg
        lengthCm
        widthCm
        heightCm
        units {
          id
          nameRU
          nameEN
          tradeMark
          code
          quantity
          price
          country {
            ...FragmentDeliveryCountry
          }
          netWeight
        }
      }
      typeDelivery {
        id
        name
        serviceName
      }
      fileTokenInvoiceXlsx
      senderNotes
      senderMark
      additionalInsurance
      declaredWeight
      actualWeight
      declaredAmount
      actualAmount
      trackNumber
      carrierCode
      deliveryStatusId
      paymentStatusId
      currency
    }
  }
`;
