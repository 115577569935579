import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  listItem: {
    fontSize: 18,
  },
  linkFlexed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  linkIcon: {
    fill: '#58a6ff',
    verticalAlign: 'middle',
  },
}));
