import React, { useEffect, useState } from 'react';
import { StoreContext } from './store';
import { Box, CircularProgress } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import ups from '../assets/img/carriers/ups.svg';
import dhl from '../assets/img/carriers/dhl.svg';
import usps from '../assets/img/carriers/usps.png';
import apc from '../assets/img/carriers/apc_logistics.png';
import fedex from '../assets/img/carriers/fedex.svg';
import tnt from '../assets/img/carriers/TNT.png';
import boxberry from '../assets/img/carriers/boxberry.png';
import dpd from '../assets/img/carriers/dpd.png';
import postRussia from '../assets/img/carriers/post_russia.png';
import CDEK from '../assets/img/carriers/CDEK.png';
import amazon from '../assets/img/carriers/amazon.png';
import other from '../assets/img/carriers/other.png';
import CheckIcon from '@material-ui/icons/Check';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CancelIcon from '@material-ui/icons/Cancel';
import { getCountryFlagUrlByISO, getCountryISOByName } from './helpers';
import { COLORS } from './constants';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { DeliveryCountry } from '../generated/graphql';
import { t } from 'i18next';

const styles = {
  carrierCodeIcon: {
    maxWidth: 45,
    maxHeight: 25,
    marginRight: 5,
    marginLeft: 5,
  },
  carrierCodeSelectedItem: {
    display: 'flex',
    alignItems: 'center',
  },
  showLoadingText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
};

interface ShowLoadingTextArg {
  name?: string;
}
export const ShowLoadingText = ({ name }: ShowLoadingTextArg) => (
  <span style={{ ...styles.showLoadingText }}>
    <CircularProgress size={10} />
    &nbsp;{t('app.loading')}
    {name ? ` ${name}` : ''}...
  </span>
);

interface ConvertStatusIdToTextProps {
  type: string;
  statusId: string | number;
  defaultText?: string;
}
const ConvertStatusIdToText = ({
  type,
  statusId,
  defaultText,
}: ConvertStatusIdToTextProps) => {
  const { statuses } = React.useContext(StoreContext);
  const [statusText, setStatusText] = useState('');

  type Statuses = Record<string, any>;
  useEffect(() => {
    if (statuses && (statuses as Statuses)[type] && type && statusId) {
      const status = (statuses as Statuses)[type].find(
        (status: any) => status.id === statusId,
      );
      if (status) {
        setStatusText(status.explain);
      }
    }
    // eslint-disable-next-line
  }, [statuses, statusId]);

  return <span>{defaultText || statusText}</span>;
};
ConvertStatusIdToText.propTypes = {
  type: PropTypes.string.isRequired,
  statusId: PropTypes.number.isRequired,
};
export { ConvertStatusIdToText };

interface GetCarrierCodeImageProps {
  carrierCode: string;
}
export const GetCarrierCodeImage = ({
  carrierCode,
}: GetCarrierCodeImageProps) => {
  let imageSrc;
  switch (carrierCode) {
    case 'dhl': {
      imageSrc = dhl;
      break;
    }
    case 'dhlexpress': {
      imageSrc = dhl;
      break;
    }
    case 'ups': {
      imageSrc = ups;
      break;
    }
    case 'fedex': {
      imageSrc = fedex;
      break;
    }
    case 'usps': {
      imageSrc = usps;
      break;
    }
    case 'apc': {
      imageSrc = apc;
      break;
    }
    case 'tnt': {
      imageSrc = tnt;
      break;
    }
    case 'boxberry': {
      imageSrc = boxberry;
      break;
    }
    case 'post_russia': {
      imageSrc = postRussia;
      break;
    }
    case 'dpd': {
      imageSrc = dpd;
      break;
    }
    case 'cdek': {
      imageSrc = CDEK;
      break;
    }
    case 'amazon logistics': {
      imageSrc = amazon;
      break;
    }
    case 'other': {
      imageSrc = other;
      break;
    }
    default: {
      imageSrc = undefined;
    }
  }
  return (
    <img
      style={{ ...styles.carrierCodeIcon }}
      src={imageSrc}
      alt={carrierCode}
    />
  );
};

export const renderCarrierCodeValue = (value: string) => {
  if (value === 'ups') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={ups} alt='ups' /> UPS
      </div>
    );
  } else if (value === 'dhl') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={dhl} alt='dhl' /> DHL
      </div>
    );
  } else if (value === 'usps') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={usps} alt='usps' /> USPS
      </div>
    );
  } else if (value === 'apc') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={apc} alt='apc' /> APC
        Postal Logistics
      </div>
    );
  } else if (value === 'fedex') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={fedex} alt='fedex' />{' '}
        FEDEX
      </div>
    );
  } else if (value === 'boxberry') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img
          style={{ ...styles.carrierCodeIcon }}
          src={boxberry}
          alt='boxberry'
        />{' '}
        Boxberry
      </div>
    );
  } else if (value === 'amazon logistics') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={amazon} alt='amazon' />{' '}
        Amazon logistics
      </div>
    );
  } else if (value === 'post_russia') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img
          style={{ ...styles.carrierCodeIcon }}
          src={postRussia}
          alt='post_russia'
        />{' '}
        {t('app.russianPost')}
      </div>
    );
  } else if (value === 'cdek') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={CDEK} alt='cdek' /> CDEK
      </div>
    );
  } else if (value === 'dpd') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={dpd} alt='dpd' /> DPD
      </div>
    );
  } else if (value === 'other') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={other} alt='other' />{' '}
        {t('app.another')}
      </div>
    );
  }
};

export const ConvertCdekStatus = ({ status }: { status: string }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const iconStyle = {
    fontSize: 15,
  };

  const statuses = ['ACCEPTED', 'CANCELED', 'PENDING', 'REMOVED'];
  if (statuses.includes(status)) {
    switch (status) {
      case 'ACCEPTED':
        return (
          <Box style={style} color={COLORS.GREEN}>
            {t('app.accepted')} <CheckIcon style={iconStyle} />
          </Box>
        );
      case 'CANCELED':
        return (
          <Box style={style} color={COLORS.RED}>
            {t('app.cancelled')} <CancelIcon style={iconStyle} />
          </Box>
        );
      case 'PENDING':
        return (
          <Box style={style} color={COLORS.ORANGE}>
            {t('app.awaiting')} <HourglassEmptyIcon style={iconStyle} />
          </Box>
        );
      case 'REMOVED':
        return (
          <Box style={style} color={COLORS.RED}>
            {t('app.beingDeleted')} <DeleteForeverIcon style={iconStyle} />
          </Box>
        );
      default:
        return <Box style={style}>{t('app.unknown')}</Box>;
    }
  } else {
    return <Box style={style}>{status}</Box>;
  }
};

interface RenderCountryFlagImageProps {
  countryName?: string | null | DeliveryCountry;
  countryISO?: string;
  className?: string;
}
export const RenderCountryFlagImage = ({
  countryName,
  countryISO = '',
  className = '',
}: RenderCountryFlagImageProps) => {
  const style = {
    marginRight: 15,
    flexShrink: 0,
  };

  const ISO = countryISO || getCountryISOByName(countryName);

  return !!ISO ? (
    <div
      className={['PhoneInputCountryIcon', className].join(' ')}
      style={style}
    >
      <img
        className='PhoneInputCountryIconImg'
        src={getCountryFlagUrlByISO(ISO)}
        alt={String(countryName)}
      />
    </div>
  ) : (
    <></>
  );
};

type ConvertWarehouseStatusIdToColorForAdminProps = {
  status: string;
};
export const ConvertWarehouseStatusIdToColorForAdmin = ({
  status,
}: ConvertWarehouseStatusIdToColorForAdminProps) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  switch (status) {
    case 'SHIPPED':
      return (
        <Box style={style} color={COLORS.GREEN}>
          {t('app.shipped')}
        </Box>
      );
    case 'UNSHIPPED':
      return (
        <Box style={style} color={COLORS.RED}>
          {t('app.notShipped')}
        </Box>
      );
    case 'PICKED':
      return (
        <Box style={style} color={COLORS.ORANGE}>
          {t('app.collected')}
        </Box>
      );
    default:
      return (
        <Box style={style} color={COLORS.BLACK}>
          {t('app.cancelled')}
        </Box>
      );
  }
};

interface ConvertWarehouseStatusProps {
  status: string;
}
export const ConvertWarehouseStatus = ({
  status,
}: ConvertWarehouseStatusProps) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px',
  };

  const statuses = ['CREATED', 'PROCESSING', 'ACCEPTED', 'CANCELED'];
  if (statuses.includes(status)) {
    switch (status) {
      case 'CREATED':
        return (
          <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.RED}>
            {t('app.created')}
          </Box>
        );
      case 'PROCESSING':
        return (
          <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.ORANGE}>
            {t('app.receiving')}
          </Box>
        );
      case 'ACCEPTED':
        return (
          <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.GREEN}>
            {t('app.accepted')}
          </Box>
        );
      case 'CANCELED':
        return (
          <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.GREY}>
            {t('app.cancelled')}
          </Box>
        );
      default:
        return <Box style={style}>{t('app.unknown')}</Box>;
    }
  } else {
    return <Box style={style}>{status}</Box>;
  }
};
