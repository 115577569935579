import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import NotFound from '../viewes/NotFound';
import { TRoutes } from '../utils/helpers';

import { routes } from './routes';

export default function PrivateRoutes({ role }) {
  return (
    <Switch>
      {routes.map((route) => {
        if (route.roles.includes(role)) {
          return (
            <Route
              key={route.path}
              path={route.path}
              exact
              component={route.component}
            />
          );
        } else {
          return (
            <Redirect key={route.path} from={route.path} to={TRoutes.MAIN} />
          );
        }
      })}
      <Route path={TRoutes.NOT_FOUND} exact component={NotFound} />
      <Redirect from='*' to={TRoutes.NOT_FOUND} exact />
    </Switch>
  );
}
