import React, { useState } from 'react';
import { Button, FormHelperText, Input, InputLabel } from '@material-ui/core';
import ModalBox from '../../components/ModalBox/ModalBox';
// @ts-ignore
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { User } from '../../generated/graphql';
import { OptionsObject } from 'notistack';
import { useTranslation } from 'react-i18next';

interface ModalDiscountProps {
  isDiscountModalOpen: boolean;
  setIsDiscountModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  discountUserId: React.ReactText;
  users?: User[];
  discountValue: string | number;
  setDiscountValueError: React.Dispatch<
    React.SetStateAction<number | null | string>
  >;
  setDiscountValue: React.Dispatch<React.SetStateAction<React.ReactText>>;
  discountValueError: number | string | null;
  classes: Record<string, string>;
  setDiscountUserId: React.Dispatch<React.SetStateAction<React.ReactText>>;
  setUserDiscountMutation: any;
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined,
  ) => React.ReactText;
  setUsers?: React.Dispatch<React.SetStateAction<[] | User[]>>;
  isUser?: boolean;
  userName?: string;
  userRefetch?: () => void;
  isDiscount: boolean;
}
export const ModalDiscount: React.FC<ModalDiscountProps> = ({
  isDiscountModalOpen,
  setIsDiscountModalOpen,
  discountUserId,
  users = [],
  discountValue,
  setDiscountValueError,
  setDiscountValue,
  discountValueError,
  classes,
  setDiscountUserId,
  setUserDiscountMutation,
  enqueueSnackbar,
  setUsers = () => {},
  isUser = false,
  userName,
  userRefetch = () => {},
  isDiscount,
}) => {
  const { t } = useTranslation();

  return (
    <ModalBox isOpen={isDiscountModalOpen} setOpen={setIsDiscountModalOpen}>
      <InputLabel shrink={false} htmlFor={'input-actual-weight'}>
        {isDiscount
          ? t('app.enterPersonalDiscount')
          : t('app.discountOnStorage')}{' '}
        <br />
        {t('app.forClientNumber')}
        {discountUserId} <br />
        {!isUser
          ? users.find((user) => +user.id === +discountUserId)?.name
          : userName}
      </InputLabel>
      <Input
        disableUnderline
        id={'input-actual-weight'}
        defaultValue={discountValue}
        inputProps={{
          type: 'number',
          min: 0,
          max: 100,
          step: 0.1,
        }}
        onChange={(e) => {
          if (e.target.value.length) {
            Yup.object()
              .shape({
                discount: Yup.number().test({
                  test: (value: number) => {
                    return value >= 0 && value <= 100;
                  },
                }),
              })
              .validate({ discount: e.target.value })
              .then((valid: { discount: string }) => {
                setDiscountValueError(null);
                setDiscountValue(valid.discount);
              })
              .catch(() =>
                setDiscountValueError(
                  t('app.mustbeAnumberfromperiod', { from: 0, to: 100 }),
                ),
              );
          } else {
            setDiscountValue(e.target.value);
            setDiscountValueError(t('app.requiredField'));
          }
        }}
      />
      {!!discountValueError && (
        <FormHelperText className={classes.errorMsg}>
          {discountValueError}
        </FormHelperText>
      )}

      <div className={classes.boxModalButtons}>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<CloseIcon />}
          onClick={() => {
            setIsDiscountModalOpen(false);
            setDiscountUserId(0);
          }}
        >
          {t('app.close')}
        </Button>
        <Button
          variant='contained'
          startIcon={<SaveIcon />}
          onClick={() =>
            setUserDiscountMutation({
              variables: {
                userId: +discountUserId,
                ...(isDiscount
                  ? { discount: discountValue }
                  : {
                      whFeePersonalDiscount: (discountValue as number) * 0.01,
                    }),
              },
            })
              .then((result: Record<string, any>) => {
                if (result?.data?.userDiscountManagement) {
                  if (result?.data?.userDiscountManagement?.discount) {
                    enqueueSnackbar(
                      t('app.discountSetForClient', {
                        client: `${discountUserId} ${
                          !isUser
                            ? users.find((user) => +user.id === +discountUserId)
                                ?.name
                            : userName
                        }`,
                        discountValue,
                      }),
                      { variant: 'success' },
                    );
                  } else {
                    enqueueSnackbar(
                      t('app.discountDeletedForClient', {
                        client: `${discountUserId} ${
                          users.find((user) => +user.id === +discountUserId)
                            ?.name
                        }`,
                      }),
                      { variant: 'warning' },
                    );
                  }
                  if (isUser) {
                    userRefetch();
                  } else {
                    setUsers((prevState: User[]) => {
                      return prevState?.map((user: User) => {
                        if (+user.id === +discountUserId) {
                          return {
                            ...user,
                            discount:
                              result?.data?.userDiscountManagement?.discount ||
                              0,
                            whFeePersonalDiscount:
                              result?.data?.userDiscountManagement
                                ?.whFeePersonalDiscount || 0,
                          };
                        }
                        return user;
                      });
                    });
                  }
                }
              })
              .catch((reason: Error) => {
                console.error(reason);
                enqueueSnackbar(t('app.somethingWentWrong'), {
                  variant: 'error',
                });
              })
              .finally(() => {
                setIsDiscountModalOpen(false);
              })
          }
        >
          {t('app.save')}
        </Button>
      </div>
    </ModalBox>
  );
};

export const useDiscount = () => {
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discountUserId, setDiscountUserId] = useState<string | number>(0);
  const [discountValue, setDiscountValue] = useState<string | number>(0);
  const [discountValueError, setDiscountValueError] = useState<
    number | string | null
  >(0);

  return {
    isDiscountModalOpen,
    setIsDiscountModalOpen,
    discountUserId,
    setDiscountUserId,
    discountValue,
    setDiscountValue,
    discountValueError,
    setDiscountValueError,
    isDiscount,
    setIsDiscount,
  };
};
