import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import { Parcel } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

export const ParcelDetailUnitsTable: React.FC<{
  parcel: Parcel;
}> = ({ parcel }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <caption style={{ captionSide: 'top' }}>
          {t('app.orderContents')}
        </caption>
        <TableHead>
          <TableRow>
            <TableCell align='center'>№</TableCell>
            <TableCell align='center'> {t('app.description')}</TableCell>
            <TableCell align='center'>{t('app.quantity')}</TableCell>
            <TableCell align='center'>{t('app.weightKg')}</TableCell>
            <TableCell align='center'>
              {t('app.price')} {parcel?.currency?.toUpperCase()}
            </TableCell>
            <TableCell align='center'>{t('app.brand')}</TableCell>
            <TableCell align='center'>{t('app.hsCode')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parcel?.packages?.map((packageItem, packageIndex) => {
            return (
              packageItem?.units &&
              packageItem?.units?.map((unit, unitIndex) => {
                // @ts-ignore
                return (
                  unit && (
                    <TableRow key={unit.id}>
                      <TableCell align='center'>
                        {packageIndex + 1}.{unitIndex + 1}
                      </TableCell>
                      <TableCell align='center'>
                        {unit.nameRU}/{unit.nameEN}
                      </TableCell>
                      <TableCell align='center'>{unit.quantity}</TableCell>
                      <TableCell align='center'>{unit.netWeight}</TableCell>
                      <TableCell align='center'>{unit.price}</TableCell>
                      <TableCell align='center'>{unit.tradeMark}</TableCell>
                      <TableCell align='center'>
                        {unit.code && (
                          <Tooltip title={t('app.itWillWindow')}>
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href={`https://tnved.info/TnvedTree?Code=${unit.code}`}
                            >
                              {unit.code}
                            </a>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                );
              })
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
