import gql from 'graphql-tag';

export default gql`
  mutation removeTrackingMutation($carrierCode: String!, $barCode: String!) {
    removeTracking(carrierCode: $carrierCode, barCode: $barCode) {
      deliveryStatusId
      trackNumber
      carrierCode
    }
  }
`;
