import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { filterSelectBySortOptions } from './options';
import { Maybe, SortType, UserOrder } from '../../../generated/graphql';

interface IFilterSelectSortingUsers {
  value?: Maybe<SortType>;
  setFilter: React.Dispatch<React.SetStateAction<UserOrder>>;
}

const FilterSelectUsersBySortType = ({
  value = SortType.Desc,
  setFilter,
}: IFilterSelectSortingUsers) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onChangeHandler = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    setFilter((state) => ({
      ...state,
      way: e.target.value as SortType,
    }));
  };

  return (
    <FormControl className={classes.filtersItemSelect}>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={onChangeHandler}
      >
        {filterSelectBySortOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {t(option.title)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSelectUsersBySortType;
