import gql from 'graphql-tag';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';

export default gql`
  ${DELIVERY_COUNTRY}
  mutation editActualWeightParcelMutation($id: ID!, $actualWeight: Float!) {
    editActualWeightParcel(id: $id, actualWeight: $actualWeight) {
      id
      sender {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      receiver {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      packages {
        id
        weightKg
        lengthCm
        widthCm
        heightCm
        units {
          id
          nameRU
          nameEN
          tradeMark
          code
          quantity
          price
          country {
            ...FragmentDeliveryCountry
          }
          netWeight
        }
      }
      typeDelivery {
        id
        name
        serviceName
      }
      fileTokenInvoiceXlsx
      senderNotes
      senderMark
      additionalInsurance
      declaredWeight
      actualWeight
      declaredAmount
      actualAmount
      carrierCode
      trackNumber
      deliveryStatusId
      paymentStatusId
      currency
    }
  }
`;
