export const MAX_PARCEL_WEIGHT = 150;
export const MAX_PARCEL_DIMENSION = 120;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY H:mm:ss';

export const ERRORS = {
  CANT_CANCEL_PARCEL_WITH_IS_SENT: 'app.cancelOrderError',
  FIELD_REQUIRED: 'app.fillInField',
  POSITIVE_NUMBER: 'app.positiveNumber',
  MAX_VALUE: 'app.maxValue',
};

export const TEXT = {
  GROSS: 'app.grossDefinition',
  NET: 'app.netDefinition',
};

export const COLORS = {
  GREEN: '#048c04',
  BLUE: '#58a6ff',
  RED: '#ff0000',
  YELLOW: '#ffc749',
  ORANGE: '#ff6e00',
  BLACK: '#000',
  WHITE: '#fff',
  GREY: '#808080',
};

export const PAYMENT_TYPES: { [key: string]: string } = {
  REFILL: 'app.accountReplenishment',
  REFUND: 'app.refund2',
  ORDER_PAYMENT: 'app.debitForOrder',
  CDEK: 'app.shipmentToWarehouseViaCDEK',
  CANCEL_ADJUSTMENT: 'app.cancelWeightCorrection',
  WEIGHT_ADJUSTMENT: 'app.correction',
  WAREHOUSE_STORAGE: 'app.storageOnWarehouse',
  ADMIN_OPERATION: 'app.manualReplenishment',
};

export const CURRENCIES = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD',
  GBP: 'GBP',
};

export const LOCAL_STORAGE_KEYS = {
  AUTH_TOKEN: 'authToken',
  IS_SIDEBAR_HIDDEN: 'isSidebarHidden',
  IS_LIST_OPEN_SENDING: 'isListOpenSending',
  IS_LIST_OPEN_PAYMENTS: 'isListOpenPayments',
  IS_LIST_OPEN_SETTINGS: 'isListOpenSettings',
  IS_WAREHOUSE_PRODUCTS_LIST_OPEN: 'isListOpenWarehouseProducts',
  ITEMS_LIMIT: 'itemsLimit',
  WAREHOUSE_ID: 'warehouseId',
  WAREHOUSE_ID_ORDER: 'warehouseIdOrder',
  DELIVERY_COUNTRIES: 'deliveryCountries',
  IS_LIST_OPEN_SENDING_TO_WAREHOUSE: 'isListOpenSendingToWarehouse',
  IS_LIST_REPORTS: 'isListOpenReports',
  TAB_VALUE: 'tabValue',
};

export const UNIT_STATES = {
  UNKNOWN: 'UNKNOWN',
  NEW: 'NEW',
  USED: 'USED',
};

export const EXPORT_REASONS = {
  COMMERCIAL: 'COMMERCIAL',
  GIFT: 'GIFT',
  SAMPLE: 'SAMPLE',
  RETURN: 'RETURN',
  REPAIR: 'REPAIR',
};

export const PAYMENT_OF_TAXES = {
  RECIPIENT: 'RECIPIENT',
  SENDER: 'SENDER',
};

export const EXPORT_REASONS_TRANSLATE = {
  COMMERCIAL: { en: 'COMMERCIAL', ru: 'Продажа' },
  GIFT: { en: 'GIFT', ru: 'Подарок' },
  SAMPLE: { en: 'SAMPLE', ru: 'Образец' },
  RETURN: { en: 'RETURN', ru: 'Возврат' },
  REPAIR: { en: 'REPAIR', ru: 'Возврат для ремонта' },
};

export const PAYMENT_OF_TAXES_IMPORT = {
  CONSIGNEE: { en: 'CONSIGNEE', ru: 'Получатель' },
  SENDER: { en: 'SENDER', ru: 'Отправитель' },
};

export const VATKEY = {
  EMPTY: 'EMPTY', //default
  VAT: 'VAT',
  IOSS: 'IOSS',
  EIN: 'EIN',
};

export const DHL_INVOICE_TYPES = {
  COMMERCIAL: 'commercial',
  PROFORMA: 'proforma',
};

export const INCOTERMS = {
  DAP: 'DAP', // default
  DDP: 'DDP',
  DAT: 'DAT',
  DDU: 'DDU',
};

export const DUTIES_PAYERS = {
  CONSIGNEE: 'consignee', // грузополучатель
  SENDER: 'sender', // аккаунт отправителя
};

export const MAX_LENGTH_DESCRIPTION_DHL = 75;

export const COUNTRIES_CIS = [
  {
    iso: 'AZ',
    name: 'Азербайджанская Республика',
    nameEng: 'Azerbaijan Republic',
    id: 248,
  },
  {
    iso: 'AM',
    name: 'Республика Армения',
    nameEng: 'Respublika Armenia',
    id: 244,
  },
  {
    iso: 'BY',
    name: 'Республика Беларусь',
    nameEng: 'Republic of Belarus',
    id: 253,
  },
  {
    iso: 'KZ',
    name: 'Республика Казахстан',
    nameEng: 'Republic of Kazakhstan',
    id: 339,
  },
  { iso: 'KG', name: 'Кыргызская Республика', nameEng: 'Kyrgyzstan', id: 345 },
  { iso: 'MD', name: 'Республика Молдова', nameEng: 'Moldova', id: 369 },
  { iso: 'RU', name: 'Россия', nameEng: 'Russia', id: 459 },
  { iso: 'TJ', name: 'Республика Таджикистан', nameEng: 'Tajikistan', id: 432 },
  { iso: 'TM', name: 'Туркменистан', nameEng: 'Turkmenistan', id: 441 },
  { iso: 'UZ', name: 'Республика Узбекистан', nameEng: 'Uzbekistan', id: 450 },
  { iso: 'UA', name: 'Украина', nameEng: 'Ukraine', id: 445 },
];

export const DEBOUNCE = 10;

export const MOVEMENT_OPERATIONS: { [key: string]: string } = {
  REFILL: 'app.refill2',
  ORDER: 'app.order',
  DEFILL: 'app.deduction',
  ORDER_REFUND: 'app.refund',
  WAREHOUSE: 'app.arrivalAtWarehouse',
};

export const REPORT_TYPES: { [key: string]: string } = {
  SHIPMENTS: 'app.shipmentReport',
  TRANSACTIONS: 'app.transactionReport',
};

export const WAREHOUSE_OPERATIONS = {
  ARRIVAL_ORDERS: 'Приход на склад',
  FORWARDING: 'Форвардинг',
  FULFILLMENT: 'Отправка со склада',
  RETURNS: 'Возвраты',
};

export const DELIVERY_STATUS_BY_ID: { [key: number]: string } = {
  1: 'app.created',
  3: 'app.inTransit',
  4: 'app.accepted',
  5: 'app.delivered',
  7: 'app.exception',
  9: 'app.canceled',
};

export const PAYMENT_ORDER_STATUS_BY_ID: { [key: number]: string } = {
  1: 'app.awaitingPayment',
  2: 'app.paid',
  3: 'app.partialPayment',
  4: 'app.refund',
  5: 'app.notRequired',
};
