import gql from 'graphql-tag';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';

export const GET_PARCELS_BY_TYPE_QUERY = gql`
  ${DELIVERY_COUNTRY}
  query GetParcelsByType(
    $offset: Int
    $limit: Int
    $search: String
    $date: Int
    $type: ParcelType
    $deliveryStatusId: Int
    $paymentStatusId: Int
  ) {
    getParcelsByType(
      offset: $offset
      limit: $limit
      search: $search
      date: $date
      type: $type
      deliveryStatusId: $deliveryStatusId
      paymentStatusId: $paymentStatusId
    ) {
      count
      rows {
        id
        user {
          id
          senderAccount
          name
        }
        sender {
          id
          name
          company
          country {
            ...FragmentDeliveryCountry
          }
          address
          address2
          address3
          zipCode
          city
          state
          phone
          email
          innKppCode
          remark
          eoriCode
        }
        receiver {
          id
          name
          company
          country {
            ...FragmentDeliveryCountry
          }
          address
          address2
          address3
          zipCode
          city
          state
          phone
          email
          innKppCode
          remark
          eoriCode
        }
        packages {
          id
          weightKg
          lengthCm
          widthCm
          heightCm
          units {
            id
            nameRU
            nameEN
            tradeMark
            code
            quantity
            price
            country {
              ...FragmentDeliveryCountry
            }
            netWeight
            state
          }
        }
        typeDelivery {
          id
          name
          serviceName
        }
        fileTokenInvoiceXlsx
        senderNotes
        senderMark
        additionalInsurance
        declaredAmount
        declaredWeight
        actualAmount
        actualWeight
        trackNumber
        carrierCode
        deliveryStatusId
        paymentStatusId
        pathNameFile
        isConsolidationPiece
        barcode
        orderCostCurrency
        createdAt
        warehouseId
        warehouse {
          id
          code
          address
          address2
          company
          phone
          country {
            ...FragmentDeliveryCountry
          }
          managerName
          city
          zipCode
          state
          email
        }
      }
    }
  }
`;
