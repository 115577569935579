import React, { useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import ModalBox from '../../components/ModalBox/ModalBox';
import { useSnackbar } from 'notistack';
import { useQuery } from '@apollo/client';
import {
  Link,
  NavLink,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import client from '../../GraphQL/client';
import { QUERY_PARCEL } from '../../GraphQL/queries/getParcel';
import cancelParcelMutation from '../../GraphQL/mutations/cancelParcel';
import { getStatusIdByName, TRoutes } from '../../utils/helpers';
import useStyles from './styles';
import moment from 'moment';
import {
  ConvertStatusIdToText,
  GetCarrierCodeImage,
  ShowLoadingText,
} from '../../utils/helperComponents';
import { StoreContext } from '../../utils/store';
import {
  COLORS,
  DATE_FORMAT,
  DELIVERY_STATUS_BY_ID,
  ERRORS,
  PAYMENT_ORDER_STATUS_BY_ID,
} from '../../utils/constants';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ParcelDetailInformation } from '../../components/ParcelDetails/ParcelDetailInformation';
import { ParcelDetailUnitsTable } from '../../components/ParcelDetails/ParcelDetailUnitsTable';
import ParcelDetailsContact from '../../components/ParcelDetails/ParcelDetailsContact/ParcelDetailsContact';
import { useTranslation } from 'react-i18next';

export default function ParcelDetail() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMediaQueryXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { statuses } = React.useContext(StoreContext);
  const statusIdCancel = getStatusIdByName(statuses?.delivery, 'cancel');

  const [isLoadingAction, setLoadingAction] = useState(false);
  const [isOpenConfirmEdit, setOpenConfirmEdit] = useState(false);
  const [isOpenConfirmCancel, setOpenConfirmCancel] = useState(false);
  const { id } = useParams<{ id: string }>();
  const isValidId = !isNaN(Number(id)) && Number.isInteger(Number(id));

  const { t } = useTranslation();

  const {
    loading: isLoadingParcel,
    error,
    data,
  } = useQuery(QUERY_PARCEL, {
    variables: { id },
  });

  if (!isValidId) {
    return <Redirect to={TRoutes.NOT_FOUND} />;
  }
  if (isLoadingParcel) {
    return <ShowLoadingText />;
  }
  if (error) {
    enqueueSnackbar(error.message, { variant: 'error' });
  }

  const handlerCancel = (id: string | number) => {
    setLoadingAction(true);
    client
      .mutate({
        mutation: cancelParcelMutation,
        variables: { id },
      })
      .then(() => {
        enqueueSnackbar(t('app.success'), { variant: 'success' });
        setLoadingAction(false);
        history.push(TRoutes.PARCELS);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        setLoadingAction(false);
      });
  };

  const parcel = data ? data.parcel : {};

  return (
    <>
      <Typography variant='h2' align='center' className={classes.mainTitle}>
        {t('app.viewOrder')}
      </Typography>
      <List>
        <ListItem>
          <Typography variant='h6'>
            {t('app.orderNumber')}:{' '}
            {parcel?.barcode?.substring(0, parcel?.barcode.length - 4)}
          </Typography>
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {t('app.creationDate')}:{' '}
          {moment(Number(parcel.createdAt)).format(DATE_FORMAT)}
        </ListItem>

        <ListItem className={classes.itemInfo}>
          {t('app.orderClient')}:{'  '}
          <Link
            to={TRoutes.PAYMENT_STATISTICS_PER_USER.replace(
              ':userId',
              String(parcel?.user?.id),
            )}
          >
            {parcel?.user?.name}
          </Link>
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {t('app.variant')}: {parcel?.typeDelivery?.name}
        </ListItem>
        {parcel?.consolidationTrackNumber?.consolidationTrackNumber ? (
          <ListItem className={classes.itemInfo}>
            {t('app.consolidationNumber')}:
            <Tooltip
              title={parcel?.consolidationTrackNumber?.service?.toUpperCase()}
            >
              <GetCarrierCodeImage
                carrierCode={parcel?.consolidationTrackNumber?.service}
              />
            </Tooltip>
            <NavLink
              to={TRoutes.PARCEL_TRACK_WITH_CARRIER.replace(
                ':carrierCodeParam',
                parcel?.consolidationTrackNumber?.service,
              ).replace(
                ':trackNumberParam',
                parcel?.consolidationTrackNumber?.consolidationTrackNumber,
              )}
            >
              <span>
                {parcel?.consolidationTrackNumber?.consolidationTrackNumber}
              </span>
            </NavLink>
          </ListItem>
        ) : null}

        <ListItem className={classes.itemInfo}>
          {t('app.deliveryStatus')}:&nbsp;
          <ConvertStatusIdToText
            type='delivery'
            statusId={parcel.deliveryStatusId}
            defaultText={t(
              DELIVERY_STATUS_BY_ID[Number(parcel.deliveryStatusId)],
            )}
          />
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {t('app.paymentStatus')}:&nbsp;
          <ConvertStatusIdToText
            type='payment'
            statusId={parcel.paymentStatusId}
            defaultText={t(
              PAYMENT_ORDER_STATUS_BY_ID[Number(parcel.paymentStatusId)],
            )}
          />
        </ListItem>
        {parcel.trackNumber && parcel.carrierCode && (
          <ListItem className={classes.itemInfo}>
            {t('app.trackNumber')}:
            <Tooltip title={parcel.carrierCode.toUpperCase()}>
              <GetCarrierCodeImage carrierCode={parcel.carrierCode} />
            </Tooltip>
            <NavLink
              to={TRoutes.PARCEL_TRACK_WITH_CARRIER.replace(
                ':carrierCodeParam',
                parcel.carrierCode,
              ).replace(':trackNumberParam', parcel.trackNumber)}
            >
              <span>{parcel.trackNumber}</span>
            </NavLink>
          </ListItem>
        )}
        {parcel.senderNotes && (
          <ListItem className={classes.itemInfo}>
            {t('app.senderNote')}:&nbsp;{parcel.senderNotes}
          </ListItem>
        )}
        {parcel.senderMark && (
          <ListItem className={classes.itemInfo}>
            {t('app.orderNumberLit')}:&nbsp;{parcel.senderMark}
          </ListItem>
        )}
      </List>
      <Grid container spacing={0} alignItems='stretch'>
        <Grid item sm={6} xs={12}>
          <ParcelDetailsContact type='sender' contact={parcel?.sender} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ParcelDetailsContact type='receiver' contact={parcel?.receiver} />
        </Grid>
      </Grid>

      <ParcelDetailInformation parcel={parcel} />

      <ParcelDetailUnitsTable parcel={parcel} />

      <div className={classes.wrapButtons}>
        <Tooltip title={t('app.goToAllOrdersList')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            startIcon={<ArrowBackIcon />}
            onClick={() => history.push('/')}
          >
            {t('app.back')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.downloadInvoiceXlsx')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            startIcon={<GetAppIcon />}
            onClick={() => {
              parcel.fileTokenInvoiceXlsx
                ? window.open(
                    `${process.env.REACT_APP_API_DOMAIN}/upload/${parcel.fileTokenInvoiceXlsx}.xlsx`,
                  )
                : enqueueSnackbar(t('app.tokenFileError'), {
                    variant: 'error',
                  });
            }}
          >
            {t('app.download')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.downloadLabel')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            startIcon={<GetAppIcon />}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_API_DOMAIN}/api/getReceipt/${parcel?.pathNameFile}`,
              );
            }}
          >
            {t('app.label')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.openBarcode')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            startIcon={<GetAppIcon />}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${parcel?.barcode}`,
              );
            }}
          >
            {t('app.barcode')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.cannotEditAfterCancel')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            startIcon={<CancelIcon style={{ color: COLORS.RED }} />}
            disabled={
              isLoadingAction || parcel.deliveryStatusId === statusIdCancel
            }
            onClick={() => {
              if (parcel.isSent) {
                enqueueSnackbar(t(ERRORS.CANT_CANCEL_PARCEL_WITH_IS_SENT), {
                  variant: 'error',
                });
              } else {
                setOpenConfirmCancel(true);
              }
            }}
          >
            {t('app.cancel')}
          </Button>
        </Tooltip>
      </div>

      <ModalBox isOpen={isOpenConfirmEdit} setOpen={setOpenConfirmEdit}>
        <Typography variant='h6'>{t('app.goToOrderEditingPage')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => setOpenConfirmEdit(false)}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() => history.push(`${TRoutes.PARCELS}/edit/${parcel.id}`)}
          >
            {t('app.goTo')}
          </Button>
        </div>
      </ModalBox>

      <ModalBox isOpen={isOpenConfirmCancel} setOpen={setOpenConfirmCancel}>
        <Typography variant='h6'>{t('app.confirmCancelOrder')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setOpenConfirmCancel(false)}
          >
            {t('app.back')}
          </Button>
          <Button
            variant='contained'
            onClick={() => handlerCancel(parcel.id)}
            disabled={
              isLoadingAction || parcel.deliveryStatusId === statusIdCancel
            }
          >
            {t('app.cancelTheOrder')}
          </Button>
        </div>
      </ModalBox>
    </>
  );
}
