import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  DEPARTURE_DATE_FILTER,
  getStatusIdByName,
  LIMIT_ITEM_PAGE,
} from '../../utils/helpers';
import cancelParcelMutation from '../../GraphQL/mutations/cancelParcel';
import useStyles from './styles';
import ParcelTableRowItem from '../../components/ParcelTableRowItem/ParcelTableRowItem';
import { isObject } from 'formik';
import { StoreContext } from '../../utils/store';
import {
  renderCarrierCodeValue,
  ShowLoadingText,
} from '../../utils/helperComponents';
import ModalBox from '../../components/ModalBox/ModalBox';
import FormEditTrackNumber from '../../components/FormEditTrackNumber/FormEditTrackNumber';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import removeTracking from '../../GraphQL/mutations/removeTracking';
import { useDebouncedCallback } from 'use-debounce';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { MUTATION_IMPORT_OF_ACTUAL_WEIGHT } from '../../GraphQL/mutations/importOfActualWeight';
import {
  DELIVERY_STATUS_BY_ID,
  LOCAL_STORAGE_KEYS,
  PAYMENT_ORDER_STATUS_BY_ID,
} from '../../utils/constants';
import { MUTATION_REMOVE_WEIGHT } from '../../GraphQL/mutations/removeWeight';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { GET_PARCELS_BY_TYPE_QUERY } from '../../GraphQL/queries/getParcelsByType';
import { Parcel } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

interface IFilterTabs {
  search: string;
  page: number;
}

interface ParcelsAllHistoryProps {
  userAccount: boolean;
  filterTabs: IFilterTabs;
  type: string;
}

export default function ParcelsAllHistory({
  userAccount,
  filterTabs,
  type,
}: ParcelsAllHistoryProps) {
  const client = useApolloClient();
  const { statuses } = React.useContext(StoreContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [parcelList, setParcelList] = useState<[] | Parcel[]>([]);
  const [parcelToCancel, setParcelToCancel] = useState<any>(null);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isOpenConfirmCancel, setIsOpenConfirmCancel] = useState(false);

  const [isOpenFormTrackNumber, setIsOpenEditTrackNumber] = useState(false);
  const [isOpenRemoveTrackNumberModalBox, setIsOpenRemoveTrackNumberModalBox] =
    useState(false);
  const [isOpenRemoveActualWeight, setIsOpenRemoveActualWeight] =
    useState(false);

  const [currentChoiceParcel, setCurrentChoiceParcel] = useState(null);
  const [trackNumberToRemove, setTrackNumberToRemove] = useState<
    null | undefined | any
  >(null);
  const [actualWeightToRemove, setActualWeightToRemove] = useState<
    null | undefined | any
  >(null);

  const { handleSetConsolidation, consolidation } =
    useCreateConsolidation(parcelList);

  const [filter, setFilter] = useState({
    paymentStatusId: 0,
    deliveryStatusId: 0,
    date: DEPARTURE_DATE_FILTER.ALL_TIME,
    search: filterTabs?.search,
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const [getParcelsLazyQuery, { loading: isLoadingParcels, error, data }] =
    useLazyQuery(GET_PARCELS_BY_TYPE_QUERY, { fetchPolicy: 'network-only' });

  const [importOfActualWeight, { loading: isLoadingImportOfActualWeight }] =
    useMutation(MUTATION_IMPORT_OF_ACTUAL_WEIGHT);

  useEffect(() => {
    data?.getParcelsByType?.rows && setParcelList(data.getParcelsByType.rows);
  }, [data]);

  useEffect(() => {
    refetchParcels();
    // eslint-disable-next-line
  }, [filter, userAccount]);

  useEffect(() => {
    setFilter((prev) => ({ ...prev, search: filterTabs?.search }));
    // eslint-disable-next-line
  }, [filterTabs]);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });

  const refetchParcels = useDebouncedCallback(() => {
    setIsLoadingAction(true);
    getParcelsLazyQuery({
      variables: {
        offset: (filter.page - 1) * filter.itemsLimit,
        limit: filter.itemsLimit,
        search: filter.search,
        date:
          typeof filter.date === 'string'
            ? DEPARTURE_DATE_FILTER.ALL_TIME
            : filter.date,
        deliveryStatusId:
          typeof filter.deliveryStatusId === 'string'
            ? 0
            : filter.deliveryStatusId,
        paymentStatusId:
          typeof filter.paymentStatusId === 'string'
            ? 0
            : filter.paymentStatusId,
        type,
      },
    });
    setIsLoadingAction(false);
  }, 400);

  const handlerCancel = (id: string | number | null) => {
    setIsLoadingAction(true);
    client
      .mutate({
        mutation: cancelParcelMutation,
        variables: { id },
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.cancelParcel &&
          response.data.cancelParcel.deliveryStatusId &&
          response.data.cancelParcel.paymentStatusId
        ) {
          setParcelList((prevState) => {
            return (prevState as Parcel[]).map((parcel) => {
              if (parcel.id === id) {
                parcel.deliveryStatusId =
                  response.data.cancelParcel.deliveryStatusId;
                parcel.paymentStatusId =
                  response.data.cancelParcel.paymentStatusId;
              }
              return parcel;
            });
          });

          enqueueSnackbar(t('app.orderCanceled', { id }), {
            variant: 'success',
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setIsLoadingAction(false);
        setIsOpenConfirmCancel(false);
        setParcelToCancel(null);
      });
  };

  const handleRemoveTrackNumber = (
    parcelId: string,
    carrierCode: string,
    trackNumber: string,
  ) => {
    if (parcelId && carrierCode && trackNumber) {
      setIsLoadingAction(true);
      client
        .mutate({
          mutation: removeTracking,
          variables: {
            carrierCode: carrierCode,
            barCode: trackNumber,
          },
        })
        .then((response) => {
          if (response && response.data && response.data.removeTracking) {
            enqueueSnackbar(
              t('app.orderCanceled', {
                num: `${carrierCode.toUpperCase()} ${trackNumber}`,
                orderNum: parcelId,
              }),
              { variant: 'success' },
            );
            setParcelList((prevState) => {
              return (prevState as Parcel[]).map((parcel) => {
                if (parcel.id === parcelId) {
                  parcel.trackNumber = response.data.removeTracking.trackNumber;
                  parcel.carrierCode = response.data.removeTracking.carrierCode;
                  parcel.deliveryStatusId =
                    response.data.removeTracking.deliveryStatusId;
                }
                return parcel;
              });
            });
          }
        })
        .catch((reason) =>
          enqueueSnackbar(reason.message, { variant: 'error' }),
        )
        .finally(() => {
          setIsOpenRemoveTrackNumberModalBox(false);
          setTrackNumberToRemove(undefined);
          setIsLoadingAction(false);
        });
    } else {
      console.error('id, carrierCode, trackNumber required!');
    }
  };

  const handleRemoveActualWeight = (
    parcelId: string | number,
    actualWeight: string | number,
  ) => {
    if (parcelId && actualWeight) {
      setIsLoadingAction(true);

      client
        .mutate({
          mutation: MUTATION_REMOVE_WEIGHT,
          variables: {
            parcelId: +parcelId,
          },
        })
        .then(() => {
          enqueueSnackbar(
            t('app.actualWeightDeleted', { parcelId, actualWeight }),
            { variant: 'success' },
          );
          setParcelList((prevState) => {
            return (prevState as Parcel[]).map((parcel) => {
              if (parcel.id === parcelId) {
                parcel.actualWeight = null;
                parcel.actualAmount = null;
              }
              return parcel;
            });
          });
          setIsOpenRemoveActualWeight(false);
          setActualWeightToRemove(undefined);
        })
        .catch((reason) => {
          console.error(reason);
          reason?.message &&
            enqueueSnackbar(reason?.message, { variant: 'error' });
        })
        .finally(() => {
          setIsLoadingAction(false);
        });
    } else {
      console.error('parcelId and actualWeight required!');
    }
  };

  const selectFileUpload = () => {
    let fileInput = document.getElementById('inputUploadFile');
    if (!fileInput) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('id', 'inputUploadFile');
      fileInput.setAttribute(
        'accept',
        '.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
      );
      fileInput.style.visibility = 'hidden';
      document.body.append(fileInput);
      fileInput.addEventListener('change', uploadFile as any, false);
      (fileInput as any).type = 'file';
    }
    fileInput.click();
  };

  const uploadFile = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    let dataArray = new FormData();
    const inputUploadFile = document.getElementById('inputUploadFile');

    if ((inputUploadFile as any)?.files?.length) {
      dataArray.append('file', (inputUploadFile as any).files[0]);

      validity.valid &&
        importOfActualWeight({
          variables: {
            file: file,
          },
        })
          .then((value) => {
            if (value?.data?.importOfActualWeight) {
              enqueueSnackbar(t('app.fileReceivedProcessed'), {
                variant: 'success',
              });
              getParcelsLazyQuery();
            } else {
              enqueueSnackbar(t('app.serverSuccessError'), {
                variant: 'success',
              });
            }
          })
          .catch((reason) => {
            console.log(reason);
            reason?.message &&
              enqueueSnackbar(reason?.message, { variant: 'error' });
          });
      (inputUploadFile as any).remove();
    }
  };

  return (
    <>
      <div className={classes.filters}>
        <FormControl className={classes.filtersItemSelect}>
          <Select
            displayEmpty
            disableUnderline
            value={filter.date}
            onChange={(e) => {
              setFilter(
                (state) =>
                  ({
                    ...state,
                    date: e.target.value,
                    page: 1,
                  } as typeof filter),
              );
            }}
          >
            <MenuItem value='' disabled>
              {t('app.periods')}
            </MenuItem>
            <MenuItem value={DEPARTURE_DATE_FILTER.ALL_TIME}>
              {t('app.forAllTime')}
            </MenuItem>
            <MenuItem value={DEPARTURE_DATE_FILTER.LAST_7}>
              {t('app.lastDays', { value: 7 })}
            </MenuItem>
            <MenuItem value={DEPARTURE_DATE_FILTER.LAST_30}>
              {t('app.lastDays', { value: 30 })}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.filtersItemSelect}>
          <Select
            displayEmpty
            disableUnderline
            value={filter.deliveryStatusId}
            onChange={(e) => {
              setFilter(
                (state) =>
                  ({
                    ...state,
                    deliveryStatusId: e.target.value,
                    page: 1,
                  } as typeof filter),
              );
            }}
          >
            <MenuItem value='' disabled>
              {t('app.deliveryStatus')}
            </MenuItem>
            <MenuItem value={0}> {t('app.allDeliveryStatuses')}</MenuItem>
            {statuses?.delivery?.map((status: any) => (
              <MenuItem key={status.id} value={status.id}>
                {t(DELIVERY_STATUS_BY_ID[Number(status.id)])}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.filtersItemSelect}>
          <Select
            displayEmpty
            disableUnderline
            value={filter.paymentStatusId}
            onChange={(e) => {
              setFilter(
                (state) =>
                  ({
                    ...state,
                    paymentStatusId: e.target.value,
                    page: 1,
                  } as typeof filter),
              );
            }}
          >
            <MenuItem value='' disabled>
              {t('app.paymentStatus')}
            </MenuItem>
            <MenuItem value={0}>{t('app.allPaymentStatuses')}</MenuItem>
            {statuses?.payment?.map((status: any) => (
              <MenuItem key={status.id} value={status.id}>
                {t(PAYMENT_ORDER_STATUS_BY_ID[Number(status.id)])}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.filtersItemSelect}>
          <BoxCentered pl={2}>
            <span style={{ paddingLeft: '24px' }}>{t('app.show')}</span>
            <Select
              displayEmpty
              disableUnderline
              value={filter.itemsLimit}
              onChange={(e) => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.ITEMS_LIMIT,
                  e.target.value as string,
                );
                setFilter(
                  (state) =>
                    ({
                      ...state,
                      itemsLimit: e.target.value,
                      page: 1,
                    } as typeof filter),
                );
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </BoxCentered>
        </FormControl>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='All Parcels History Table'>
          <TableHeadCustom />
          <TableBody>
            {(!data || isLoadingParcels || isLoadingAction) && (
              <TableRow>
                <TableCell colSpan={13}>
                  <ShowLoadingText />
                </TableCell>
              </TableRow>
            )}
            {!isLoadingParcels && !isLoadingAction && parcelList.length
              ? (parcelList as Parcel[]).map((parcel) => (
                  <ParcelTableRowItem
                    key={parcel.id}
                    parcel={parcel}
                    setIsOpenEditTrackNumber={setIsOpenEditTrackNumber}
                    setIsOpenRemoveTrackNumber={
                      setIsOpenRemoveTrackNumberModalBox
                    }
                    setTrackNumberToRemove={
                      setTrackNumberToRemove as (value: unknown) => void
                    }
                    setCurrentChoiceParcel={
                      setCurrentChoiceParcel as (value: unknown) => void
                    }
                    setIsOpenConfirmCancel={
                      setIsOpenConfirmCancel as (value: unknown) => void
                    }
                    setParcelToCancel={
                      setParcelToCancel as (value: unknown) => void
                    }
                    setActualWeightToRemove={
                      setActualWeightToRemove as (value: unknown) => void
                    }
                    setIsOpenRemoveActualWeight={setIsOpenRemoveActualWeight}
                    userAccount={userAccount}
                    handleSetConsolidation={handleSetConsolidation}
                    consolidation={consolidation}
                    type={type}
                  />
                ))
              : !!data &&
                !isLoadingParcels &&
                !isLoadingAction && (
                  <TableRow>
                    <TableCell colSpan={13} align='center'>
                      {t('app.noData')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        className={classes.pagination}
        count={
          data?.getParcelsByType?.count
            ? Math.ceil(data?.getParcelsByType?.count / filter.itemsLimit)
            : 1
        }
        page={filter.page}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />

      <Box mt={2}>
        <Button
          variant='contained'
          startIcon={
            isLoadingImportOfActualWeight ? (
              <CircularProgress size={22} />
            ) : (
              <CloudUploadIcon />
            )
          }
          onClick={selectFileUpload}
          disabled={isLoadingImportOfActualWeight}
        >
          {t('app.uploadTrackingWeight')}
        </Button>
      </Box>

      <FormEditTrackNumber
        setParcelList={setParcelList}
        isOpenEditTrackNumber={isOpenFormTrackNumber}
        setIsOpenEditTrackNumber={setIsOpenEditTrackNumber}
        setOpenEditTrackNumberModalBox={setIsOpenEditTrackNumber}
        currentChoiceParcel={currentChoiceParcel}
      />

      <ModalBox
        isOpen={isOpenRemoveActualWeight && !!actualWeightToRemove}
        setOpen={setIsOpenRemoveActualWeight}
      >
        <Typography variant='h6'>
          {t('app.confirmDeleteActualWeight')}
        </Typography>
        {actualWeightToRemove &&
          actualWeightToRemove.parcelId &&
          actualWeightToRemove.actualWeight &&
          actualWeightToRemove.declaredWeight && (
            <div>
              <br />
              <div>
                {t('app.orderNumberLabel')}:&nbsp;
                <a
                  href={`/parcels/${actualWeightToRemove.parcelId}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {actualWeightToRemove.parcelId}
                </a>
              </div>
              <div>
                {t('app.declaredWeight')}:&nbsp;
                {actualWeightToRemove.declaredWeight}
              </div>
              <div>
                {t('app.actualWeight')}:&nbsp;
                {actualWeightToRemove.actualWeight}
              </div>
            </div>
          )}
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setIsOpenRemoveActualWeight(false);
              setActualWeightToRemove(undefined);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              handleRemoveActualWeight(
                actualWeightToRemove.parcelId,
                actualWeightToRemove.actualWeight,
              );
            }}
            disabled={isLoadingAction}
          >
            <DeleteForeverIcon style={{ color: 'red' }} /> Удалить
          </Button>
        </div>
      </ModalBox>

      <ModalBox
        isOpen={isOpenRemoveTrackNumberModalBox && !!trackNumberToRemove}
        setOpen={setIsOpenRemoveTrackNumberModalBox}
      >
        <Typography variant='h6'>
          {t('app.confirmDeleteTrackingNumber')}
        </Typography>
        {trackNumberToRemove &&
          trackNumberToRemove.parcelId &&
          trackNumberToRemove.trackNumber &&
          trackNumberToRemove.carrierCode && (
            <div>
              <br />
              <div>
                {t('app.orderNumberLabel')}:&nbsp;
                <a
                  href={`/parcels/${trackNumberToRemove.parcelId}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {trackNumberToRemove.parcelId}
                </a>
              </div>
              <div>
                {t('app.trackingNumberLabel')}:&nbsp;
                <a
                  href={`track/${trackNumberToRemove.carrierCode}/${trackNumberToRemove.trackNumber}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {trackNumberToRemove.trackNumber}
                </a>
              </div>
              {renderCarrierCodeValue(trackNumberToRemove.carrierCode)}
            </div>
          )}
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setIsOpenRemoveTrackNumberModalBox(false);
              setTrackNumberToRemove(undefined);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              handleRemoveTrackNumber(
                trackNumberToRemove.parcelId,
                trackNumberToRemove.carrierCode,
                trackNumberToRemove.trackNumber,
              );
            }}
            disabled={isLoadingAction}
          >
            <DeleteForeverIcon style={{ color: 'red' }} /> {t('app.delete')}
          </Button>
        </div>
      </ModalBox>

      <ModalBox
        isOpen={isOpenConfirmCancel && isObject(parcelToCancel)}
        setOpen={setIsOpenConfirmCancel}
      >
        <Typography variant='h6'>{t('app.confirmCancelOrder')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setIsOpenConfirmCancel(false);
              setParcelToCancel(null);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() => handlerCancel(parcelToCancel?.id)}
            disabled={
              isLoadingAction ||
              (isObject(parcelToCancel) &&
                parcelToCancel?.deliveryStatusId ===
                  getStatusIdByName(statuses.delivery, 'cancel'))
            }
          >
            {t('app.cancelTheOrder')}
          </Button>
        </div>
      </ModalBox>
    </>
  );
}

export const TableHeadCustom = () => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <TableCell align='center' rowSpan={2}>
          {t('app.orderNumber')}
        </TableCell>
        <TableCell align='center' rowSpan={2}>
          {t('app.orderClient')}
        </TableCell>
        <TableCell align='center' rowSpan={2}>
          {t('app.cost')}
        </TableCell>
        <TableCell align='center' rowSpan={2}>
          {t('app.trackingNumberLabel')}
        </TableCell>
        <TableCell align='center' rowSpan={2}>
          {t('app.deliveryStatus')}
        </TableCell>
        <TableCell align='center' rowSpan={2}>
          {t('app.payment')}
        </TableCell>
        <TableCell align='center' rowSpan={2}>
          {t('app.action')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const useCreateConsolidation = (parcelList: any[]) => {
  const [consolidation, setConsolidation] = useState<any>({});

  const handleSetConsolidation = (key: string) => {
    setConsolidation((prevfilter: any) => {
      return {
        ...prevfilter,
        [key]: !prevfilter[key],
      };
    });
  };

  const createArrayId = (data: Record<string, any>) => {
    const arrayId: any[] = [];
    Object.keys(data).map((e) => {
      if (data[e]) {
        return arrayId.push(parcelList.find((item) => e === item.id));
      }
      return null;
    });
    return arrayId;
  };
  const arrayConsolidation = createArrayId(consolidation);

  return {
    handleSetConsolidation,
    consolidation,
    arrayConsolidation,
  };
};
