import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse, List, ListItem, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import useStyles from './styles';
import { TRoutes } from '../../utils/helpers';
import GroupIcon from '@material-ui/icons/Group';
import SearchIcon from '@material-ui/icons/Search';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ExposureIcon from '@material-ui/icons/Exposure';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import SettingsIcon from '@material-ui/icons/Settings';
import { UserRoles } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

interface SidebarProps {
  isSidebarHidden?: boolean;
  onSelectItem?: () => void;
  role?: string | UserRoles;
}
const Sidebar: React.FC<SidebarProps> = ({
  isSidebarHidden = false,
  onSelectItem = () => {},
  role,
}) => {
  const classes = useStyles(isSidebarHidden);

  const { t } = useTranslation();

  const [listsOpen, setLists] = useState({
    sending:
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_SENDING) ===
        'true' || false,
    payments:
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_PAYMENTS) ===
        'true' || false,
    settings:
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_SETTINGS) ===
        'true' || false,
    sendingToWarehouse:
      localStorage.getItem(
        LOCAL_STORAGE_KEYS.IS_LIST_OPEN_SENDING_TO_WAREHOUSE,
      ) === 'true' || false,
    reports:
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LIST_REPORTS) === 'true' ||
      false,
    warehouseProducts:
      localStorage.getItem(
        LOCAL_STORAGE_KEYS.IS_WAREHOUSE_PRODUCTS_LIST_OPEN,
      ) === 'true' || false,
  });

  const handleClickItem = (name: keyof typeof listsOpen) => () => {
    localStorage.setItem(
      'isListOpen' + name[0].toUpperCase() + name.substring(1),
      (!listsOpen[name]).toString(),
    );
    setLists({
      ...listsOpen,
      [name]: !listsOpen[name],
    });
  };

  return (
    <List className={classes.list} component='div'>
      <ListItem button className={classes.listItem}>
        <NavLink
          className={classes.listItemLink}
          exact
          activeClassName={classes.listItemLinkActive}
          onClick={() => onSelectItem()}
          to={TRoutes.CLIENTS}
        >
          <Tooltip title={t('app.clients')}>
            <GroupIcon className={classes.listItemLinkIcon} />
          </Tooltip>
          {!isSidebarHidden && t('app.clients')}
        </NavLink>
      </ListItem>

      <ListItem
        button
        className={
          role === 'SUPER_ADMIN'
            ? clsx(classes.listItem, classes.listItemSubList)
            : classes.listItem
        }
        onClick={handleClickItem('sending')}
      >
        {role !== 'SUPER_ADMIN' ? (
          <NavLink
            to={TRoutes.PARCELS}
            className={classes.listItemLink}
            activeClassName={classes.listItemLinkActive}
            exact
            onClick={() => onSelectItem()}
          >
            <Tooltip title={t('app.orders')}>
              <CardMembershipIcon className={classes.listItemLinkIcon} />
            </Tooltip>
            {!isSidebarHidden && t('app.orders')}
          </NavLink>
        ) : (
          <>
            <Tooltip title={t('app.orders')}>
              <CardMembershipIcon className={classes.listItemLinkIcon} />
            </Tooltip>
            {!isSidebarHidden && t('app.orders')}
          </>
        )}
      </ListItem>

      {role === 'SUPER_ADMIN' ? (
        <Collapse in={listsOpen.sending}>
          <List className={clsx(classes.list, classes.listSub)} component='div'>
            <ListItem
              className={clsx(classes.listItem, classes.listItemSub)}
              button
            >
              <NavLink
                to={TRoutes.PARCELS}
                className={classes.listItemLink}
                activeClassName={classes.listItemLinkActive}
                exact
                onClick={() => onSelectItem()}
              >
                {t('app.allOrders')}
              </NavLink>
            </ListItem>
          </List>
        </Collapse>
      ) : null}

      <ListItem className={classes.listItem} button>
        <NavLink
          exact
          className={classes.listItemLink}
          activeClassName={classes.listItemLinkActive}
          onClick={() => onSelectItem()}
          to={TRoutes.TRACK}
        >
          <Tooltip title={t('app.trackByTrackingNumber')}>
            <SearchIcon className={classes.listItemLinkIcon} />
          </Tooltip>
          {!isSidebarHidden && t('app.track')}
        </NavLink>
      </ListItem>

      {role === 'SUPER_ADMIN' ? (
        <ListItem className={classes.listItem} button>
          <a
            className={classes.listItemLink}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => onSelectItem()}
            href='https://shipping.picknpack.pro/calculator'
          >
            <ExposureIcon className={classes.listItemLinkIcon} />
            {!isSidebarHidden && t('app.calculator')}
          </a>
        </ListItem>
      ) : null}

      {role === 'SUPER_ADMIN' ? (
        <>
          <ListItem
            className={clsx(classes.listItem, classes.listItemSubList)}
            button
            onClick={handleClickItem('payments')}
          >
            <AccountBalanceWalletIcon className={classes.listItemLinkIcon} />
            {!isSidebarHidden && t('app.payments')}
          </ListItem>

          <Collapse in={listsOpen.payments}>
            <List
              className={clsx(classes.list, classes.listSub)}
              component='div'
            >
              <ListItem
                className={clsx(classes.listItem, classes.listItemSub)}
                button
              >
                <NavLink
                  className={classes.listItemLink}
                  activeClassName={classes.listItemLinkActive}
                  exact
                  onClick={() => onSelectItem()}
                  to={TRoutes.PAYMENT_STATISTICS}
                >
                  {t('app.statistics')}
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </>
      ) : null}
      {role === 'SUPER_ADMIN' ? (
        <ListItem
          classes={{
            root: classes.listItem,
            disabled: classes.listItemDisabled,
          }}
          button
        >
          <NavLink
            className={classes.listItemLink}
            activeClassName={classes.listItemLinkActive}
            exact
            onClick={() => {
              if (onSelectItem) return onSelectItem();
            }}
            to={TRoutes.REPORTS}
          >
            <Tooltip title={t('app.reports')}>
              <LibraryBooksIcon className={classes.listItemLinkIcon} />
            </Tooltip>
            {!isSidebarHidden && t('app.reports')}
          </NavLink>
        </ListItem>
      ) : null}

      {role === 'SUPER_ADMIN' ? (
        <>
          <ListItem
            button
            className={clsx(classes.listItem, classes.listItemSubList)}
            onClick={handleClickItem('settings')}
          >
            <Tooltip title={t('app.settings')}>
              <SettingsIcon className={classes.listItemLinkIcon} />
            </Tooltip>
            {!isSidebarHidden && t('app.settings')}
          </ListItem>

          <Collapse in={listsOpen.settings}>
            <List
              className={clsx(classes.list, classes.listSub)}
              component='div'
            >
              <ListItem
                button
                className={clsx(classes.listItem, classes.listItemSub)}
              >
                <NavLink
                  exact
                  to={TRoutes.SETTINGS_CALCULATOR}
                  className={classes.listItemLink}
                  activeClassName={classes.listItemLinkActive}
                  onClick={() => onSelectItem()}
                >
                  {t('app.calculator')}
                </NavLink>
              </ListItem>
              <ListItem
                button
                className={clsx(classes.listItem, classes.listItemSub)}
              >
                <NavLink
                  exact
                  className={classes.listItemLink}
                  activeClassName={classes.listItemLinkActive}
                  onClick={() => onSelectItem()}
                  to={TRoutes.SETTINGS_SENDER_ACCOUNTS}
                >
                  {t('app.carriers')}
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </>
      ) : null}
    </List>
  );
};

export default Sidebar;
