import React, { useEffect, useState } from 'react';
import {
  Button,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Box,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import ModalBox from '../ModalBox/ModalBox';
import { useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from './styles';
import editTrackNumberParcel from '../../GraphQL/mutations/editTrackNumberParcel';
import { OperationVariables, useApolloClient } from '@apollo/client';
import { checkAllowedCharacters } from '../../utils/helpers';
import ups from '../../assets/img/carriers/ups.svg';
import dhl from '../../assets/img/carriers/dhl.svg';
import usps from '../../assets/img/carriers/usps.png';
import apc from '../../assets/img/carriers/apc_logistics.png';
import fedex from '../../assets/img/carriers/fedex.svg';
import tnt from '../../assets/img/carriers/TNT.png';
import boxberry from '../../assets/img/carriers/boxberry.png';
import dpd from '../../assets/img/carriers/dpd.png';
import amazon from '../../assets/img/carriers/amazon.png';
import postRussia from '../../assets/img/carriers/post_russia.png';
import CDEK from '../../assets/img/carriers/CDEK.png';
import other from '../../assets/img/carriers/other.png';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

type PlaceholderProps = {
  children: React.ReactNode;
};

interface FormEditTrackNumberProps {
  setParcelList: React.Dispatch<React.SetStateAction<any[]>>;
  isOpenEditTrackNumber: boolean;
  setIsOpenEditTrackNumber: React.Dispatch<React.SetStateAction<boolean>>;
  currentChoiceParcel: any;
  isConsolidation?: boolean;
  setOpenEditTrackNumberModalBox?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const FormEditTrackNumber: React.FC<FormEditTrackNumberProps> = ({
  setParcelList,
  isOpenEditTrackNumber,
  setIsOpenEditTrackNumber,
  currentChoiceParcel,
  isConsolidation = false,
}) => {
  const client = useApolloClient();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [trackNumberValue, setTrackNumberValue] = useState('');
  const [carrierCode, setCarrierCode] = useState('');
  const [errorTrackNumberValue, setErrorTrackNumberValue] = useState<
    boolean | null
  >(false);
  const [parcelId, setParcelId] = useState<string | undefined>('');

  const { t } = useTranslation();

  useEffect(() => {
    if (currentChoiceParcel?.trackNumber) {
      setTrackNumberValue(currentChoiceParcel.trackNumber);
    }
    if (
      currentChoiceParcel?.carrierCode ||
      currentChoiceParcel?.shipmentServiceName
    ) {
      setCarrierCode(
        currentChoiceParcel.carrierCode ||
          currentChoiceParcel?.shipmentServiceName,
      );
    }
    if (currentChoiceParcel?.id) {
      setParcelId(currentChoiceParcel.id);
    }

    return () => {
      setTrackNumberValue('');
      setCarrierCode('');
      setParcelId(undefined);
    };
  }, [currentChoiceParcel]);

  const [variables, message] = getVariables(
    trackNumberValue,
    carrierCode,
    parcelId,
  );

  const handlerEdit = () => {
    setLoadingAction(true);
    client
      .mutate({
        mutation: editTrackNumberParcel,
        variables: variables as OperationVariables,
      })

      .then((response) => {
        enqueueSnackbar(message, { variant: 'success' });
        setParcelList((prevState) => {
          return prevState.map((parcel) =>
            parcel.id === currentChoiceParcel?.id
              ? response.data.editTrackNumberParcel
              : parcel,
          );
        });
        setIsOpenEditTrackNumber(false);
        setLoadingAction(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        setLoadingAction(false);
      });
  };

  const Placeholder = ({ children }: PlaceholderProps) => (
    <div className={classes.placeholder}>{children}</div>
  );

  const onClose = (isClose: boolean) => {
    setIsOpenEditTrackNumber(isClose);
    setCarrierCode('');
    setTrackNumberValue('');
  };

  return (
    <ModalBox isOpen={isOpenEditTrackNumber} setOpen={onClose}>
      <InputLabel shrink={false} htmlFor={'input-actual-weight'}>
        {isConsolidation
          ? `${t('app.enterTrackingNumber')} C${parcelId}`
          : `${t('app.enterTrackingNumber')} ${parcelId ? parcelId : ''}`}
      </InputLabel>
      <Box mb={5} className={classes.infoWrapper}>
        <Box mb={2.5}>
          <Input
            value={trackNumberValue}
            disableUnderline
            id={'input-actual-weight'}
            onChange={(e) => {
              setTrackNumberValue((prevState) => {
                return checkAllowedCharacters(e.target.value)
                  ? e.target.value
                  : prevState;
              });
              setErrorTrackNumberValue(null);
            }}
          />
          {errorTrackNumberValue && (
            <FormHelperText className={classes.errorMsg}>
              {t('app.fieldIsRequired')}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2.5}>
          <Select
            renderValue={
              carrierCode
                ? undefined
                : () => <Placeholder> {t('app.selectCarrier')}</Placeholder>
            }
            onChange={(e) => setCarrierCode(e.target.value as string)}
            value={carrierCode}
            disableUnderline
            displayEmpty
          >
            <MenuItem value='' className={classes.placeholder} disabled />

            <MenuItem
              value='amazon logistics'
              className={classes.carrierCodeSelectedItem}
            >
              <img
                className={classes.carrierCodeIcon}
                src={amazon}
                alt={'amazon'}
              />{' '}
              Amazon logistics
            </MenuItem>
            <MenuItem value='ups' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={ups} alt={'ups'} />{' '}
              UPS
            </MenuItem>
            <MenuItem value='dhl' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={dhl} alt={'dhl'} />{' '}
              DHL
            </MenuItem>
            <MenuItem value='usps' className={classes.carrierCodeSelectedItem}>
              <img
                className={classes.carrierCodeIcon}
                src={usps}
                alt={'usps'}
              />{' '}
              USPS
            </MenuItem>
            <MenuItem value='apc' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={apc} alt={'apc'} />{' '}
              APC Postal Logistics
            </MenuItem>
            <MenuItem value='fedex' className={classes.carrierCodeSelectedItem}>
              <img
                className={classes.carrierCodeIcon}
                src={fedex}
                alt={'fedex'}
              />{' '}
              FEDEX
            </MenuItem>
            <MenuItem value='tnt' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={tnt} alt={'tnt'} />{' '}
              TNT
            </MenuItem>
            <MenuItem value='apc' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={apc} alt={'apc'} />{' '}
              APC Postal Logistics
            </MenuItem>
            <MenuItem
              value='post_russia'
              className={classes.carrierCodeSelectedItem}
            >
              <img
                className={classes.carrierCodeIcon}
                src={postRussia}
                alt={'boxberry'}
              />{' '}
              {t('app.russianPost')}
            </MenuItem>
            <MenuItem value='cdek' className={classes.carrierCodeSelectedItem}>
              <img
                className={classes.carrierCodeIcon}
                src={CDEK}
                alt={'CDEK'}
              />{' '}
              CDEK
            </MenuItem>
            <MenuItem value='dpd' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={dpd} alt={'dpd'} />{' '}
              DPD
            </MenuItem>
            <MenuItem
              value='boxberry'
              className={classes.carrierCodeSelectedItem}
            >
              <img
                className={classes.carrierCodeIcon}
                src={boxberry}
                alt={'boxberry'}
              />{' '}
              BOXBERRY
            </MenuItem>
            <MenuItem value='other' className={classes.carrierCodeSelectedItem}>
              <img
                className={classes.carrierCodeIcon}
                src={other}
                alt={'other'}
              />{' '}
              {t('app.another')}
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <div className={classes.boxModalButtons}>
        <Button
          className={classes.closeButton}
          variant='contained'
          onClick={() => setIsOpenEditTrackNumber(false)}
          color='secondary'
        >
          {t('app.close')}
        </Button>
        <Button
          variant='contained'
          onClick={handlerEdit}
          disabled={
            isLoadingAction ||
            !!errorTrackNumberValue ||
            !trackNumberValue ||
            trackNumberValue.length < 4 ||
            !carrierCode
          }
        >
          {isLoadingAction ? <CircularProgress size={22} /> : <SaveIcon />}
          {t('app.save')}
        </Button>
      </div>
    </ModalBox>
  );
};

const getVariables = (
  trackNumber: string,
  carrierCode: string,
  id?: string,
) => [{ id, trackNumber, carrierCode }, t('app.trackSuccessfullyChanged')];

export default FormEditTrackNumber;
