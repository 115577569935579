import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export default makeStyles(() => ({
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  linkIcon: {
    fill: '#58a6ff',
    verticalAlign: 'middle',
  },
  wrapValueCell: {
    display: 'inline-block',
    padding: '5px 0 5px 10px',
    minWidth: '40px',
  },
  actionMenuItem: {
    padding: 0,
  },
  actionIconWatch: {
    color: COLORS.GREEN,
  },
  actionIconDownload: {
    color: COLORS.BLACK,
  },
  actionIcon: {
    paddingRight: '5px',
    fontSize: '20px',
  },
  actionIconEdit: {
    color: COLORS.ORANGE,
  },
  actionIconCancel: {
    color: COLORS.RED,
  },
  actionIconSend: {
    color: COLORS.BLUE,
  },
  actionLink: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    textDecoration: 'none',
    padding: '6px 16px',
  },
  carrierCodeIcon: {
    maxWidth: '20px',
    maxHeight: '16px',
  },
  radio: {},
}));
