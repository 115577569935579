import gql from 'graphql-tag';

export const MUTATION_DEBITING_AND_CREDITING_FUNDS = gql`
  mutation DebitingAndCreditingFundsMutation(
    $userId: Int!
    $amount: Float!
    $reason: String!
    $isDebiting: Boolean
    $type: TransactionTypes!
  ) {
    debitingAndCreditingFunds(
      userId: $userId
      amount: $amount
      reason: $reason
      isDebiting: $isDebiting
      type: $type
    ) {
      id
      userId
      amount
      reason
      paymentType
      status
      createdAt
    }
  }
`;
