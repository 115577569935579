import React, { useState } from 'react';
import { Parcel, ParcelUnit } from '../../generated/graphql';
import { List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { COLORS, TEXT } from '../../utils/constants';
import {
  sumByField,
  sumQuantityByField,
  sumTotalVolumetricWeight,
} from '../../utils/helpers';
import styles from './styles';
import {
  EXPORT_REASONS_TRANSLATE,
  PAYMENT_OF_TAXES_IMPORT,
} from '../../utils/constants';
import FormActualWeight from '../FormActualWeight/FormActualWeight';
import { useTranslation } from 'react-i18next';

export const ParcelDetailInformation: React.FC<{ parcel: Parcel }> = ({
  parcel,
}) => {
  const classes = styles();
  const [isOpenEditActualWeight, setIsOpenEditActualWeight] = useState(false);
  const { t } = useTranslation();

  const units: ParcelUnit[] = [];
  parcel?.packages?.forEach((p) => {
    if (p && p.units) {
      p.units.forEach((u) => u && units.push(u));
    }
  });

  const isVatShipment =
    parcel?.receiver?.country?.iso !== parcel?.sender?.country?.iso;

  return (
    <>
      <List>
        <ListItem>
          <Typography variant='h6'>{t('app.cargoInformation')}</Typography>
        </ListItem>
        {parcel?.packages?.map((place, index) => (
          <ListItem className={classes.listItem}>
            {`${t('app.сargoPlace')} ${index + 1}: ${place?.lengthCm} x ${
              place?.widthCm
            } x ${place?.heightCm} ${t('app.cm')}, ${place?.weightKg} ${t(
              'app.kg',
            )}`}
          </ListItem>
        ))}
        <ListItem className={classes.listItem}>
          {t('app.numberOfItems')}:{' '}
          {parcel?.packages?.reduce((a, b) => {
            return (
              a +
              // @ts-ignore
              b?.units?.reduce((c, d) => {
                return c + 1;
              }, 0)
            );
          }, 0)}
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip title={t(TEXT.GROSS)}>
            <span> {t('app.totalGrossWeight')}</span>
          </Tooltip>
          :&nbsp;
          {sumByField(parcel?.packages, 'weightKg', (val) => `${val} кг`)}
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip title={t(TEXT.NET)}>
            <span>{t('app.totalNetWeight')}</span>
          </Tooltip>
          :&nbsp;
          {sumQuantityByField(units, 'netWeight', (val) => `${val} кг`)}
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip title='(высота * длина * ширина) / 5000'>
            <span>{t('app.totalVolumetricWeight')}</span>
          </Tooltip>
          :&nbsp;
          {sumTotalVolumetricWeight(parcel?.packages, (val) => `${val} кг`)}
        </ListItem>
        <ListItem className={classes.listItem}>
          {t('app.chargeableWeightDeclared')}:&nbsp;
          {parcel?.declaredWeight} кг
        </ListItem>

        {parcel?.actualWeight &&
          parcel?.declaredWeight &&
          parcel?.actualWeight !== parcel?.declaredWeight && (
            <ListItem className={classes.listItem}>
              {t('app.chargeableWeightDifference')}:&nbsp;
              {parcel?.actualWeight - parcel?.declaredWeight} {t('app.kg')}
            </ListItem>
          )}
        <ListItem className={classes.listItem}>
          {`${t('app.itemCost')}: `}
          {sumQuantityByField(
            units,
            'price',
            (val) =>
              `${val} ${(parcel?.currency
                ? parcel?.currency
                : ''
              ).toUpperCase()}`,
          )}
        </ListItem>
        {isVatShipment ? (
          <>
            <ListItem className={classes.listItem}>
              {t('app.exportReason')}:
              <span style={{ marginLeft: '5px' }}>
                {parcel?.exportReason
                  ? //@ts-ignore
                    EXPORT_REASONS_TRANSLATE[parcel?.exportReason]?.ru
                  : null}
              </span>
            </ListItem>
            <ListItem className={classes.listItem}>
              {t('app.taxAndDutyPayment')}:
              <span style={{ marginLeft: '5px' }}>
                {parcel?.paymentOfTaxes
                  ? //@ts-ignore
                    PAYMENT_OF_TAXES_IMPORT[parcel?.paymentOfTaxes]?.ru
                  : null}
              </span>
            </ListItem>
            <ListItem className={classes.listItem}>
              {t('app.taxIDNumber')}:{parcel?.vatID}
            </ListItem>
          </>
        ) : null}
        {parcel.additionalInsurance ? (
          <ListItem className={classes.listItem}>
            {t('app.additionalInsurance')}:&nbsp;
            <span style={{ color: COLORS.GREEN }}>{t('app.yes')}</span>
          </ListItem>
        ) : null}
        {parcel.signature ? (
          <ListItem className={classes.listItem}>
            {t('app.recipientSignature')}:&nbsp;
            <span style={{ color: COLORS.GREEN }}>{t('app.yes')}</span>
          </ListItem>
        ) : null}
        <ListItem className={classes.listItem}>
          {t('app.shipmentCostDeclared')}:&nbsp;
          {parcel?.declaredAmount?.toFixed(2)} {parcel?.orderCostCurrency}
        </ListItem>
        {parcel?.actualAmount && (
          <ListItem className={classes.listItem}>
            {t('app.shipmentCostActual')}:&nbsp;
            {parcel?.actualAmount} {parcel?.orderCostCurrency}
          </ListItem>
        )}
        {parcel?.actualAmount &&
          parcel?.declaredAmount &&
          parcel?.actualAmount !== parcel?.declaredAmount && (
            <ListItem className={classes.listItem}>
              {t('app.shipmentCostDifference')}:&nbsp;
              {(parcel?.actualAmount - parcel?.declaredAmount).toFixed(2)}{' '}
              {parcel?.orderCostCurrency}
            </ListItem>
          )}
      </List>
      <FormActualWeight
        isOpenEditActualWeight={isOpenEditActualWeight}
        setOpenEditActualWeight={setIsOpenEditActualWeight}
        parcelId={parcel.id}
      />
    </>
  );
};
