import React, { useState } from 'react';
import { Button, Input, InputLabel } from '@material-ui/core';
import ModalBox from '../../components/ModalBox/ModalBox';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { User } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

type HandleSetCreditLimitType = {
  userId: number;
  value: number;
};

interface ModalCreditProps {
  isCreditModalOpen: boolean;
  setIsCreditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  users?: [] | User[];
  classes: Record<string, string>;
  userId: string | number | null;
  handleSetCreditLimit: ({ value, userId }: HandleSetCreditLimitType) => void;
  userCreditLimit?: number;
}

export const ModalCredit = ({
  isCreditModalOpen,
  setIsCreditModalOpen,
  users,
  classes,
  userId,
  handleSetCreditLimit,
  userCreditLimit,
}: ModalCreditProps) => {
  const [value, setValue] = useState<string | null>(null);

  const { t } = useTranslation();

  return (
    <ModalBox isOpen={isCreditModalOpen} setOpen={setIsCreditModalOpen}>
      <InputLabel shrink={false} htmlFor={'input-actual-weight'}>
        {t('app.enterCreditLimit')}
      </InputLabel>
      <Input
        disableUnderline
        id={'input-actual-weight'}
        inputProps={{
          type: 'number',
          min: 0,
          max: 100,
          step: 0.1,
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        defaultValue={userCreditLimit}
      />

      <div className={classes.boxModalButtons}>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<CloseIcon />}
          onClick={() => {
            setIsCreditModalOpen(false);
          }}
        >
          {t('app.close')}
        </Button>
        <Button
          variant='contained'
          startIcon={<SaveIcon />}
          onClick={(e) => {
            handleSetCreditLimit({
              userId: parseInt(userId as string),
              value: parseInt(value as string),
            });
            setIsCreditModalOpen(false);
          }}
        >
          {t('app.save')}
        </Button>
      </div>
    </ModalBox>
  );
};

export const useCredit = () => {
  const [isCreditModalOpen, setIsCreditModalOpen] = useState(false);
  const [userId, setUserId] = useState<number | null | string>(null);
  const [creditValue, setCreditValue] = useState<number>(0);
  return {
    isCreditModalOpen,
    setIsCreditModalOpen,
    userId,
    setUserId,
    creditValue,
    setCreditValue,
  };
};
